import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Button as AntdButton } from "antd";
import MuiButton from "@mui/material/Button";
import "./vitalsModel.css";
import { TimePicker } from "antd";
import moment from "moment";
import {
  ChevronLeft,
  ChevronRight,
  ContentCopyRounded,
} from "@mui/icons-material";
import { BarLoader, BounceLoader } from "react-spinners";
import axios from "axios";
import CustomToast from "../CustomToast";

const VitalsModal = ({
  open,
  VitalsItems,
  onClose,
  patientId,
  patientIdForPreviousTreatment,
  handleCellChange,
  handleOpenTreatmentNoteModal,
  recordForNotes,
  selectedDate,
}) => {
  const [updatedItems, setUpdatedItems] = useState([]);
  const [modalLoader, setModalLoader] = useState(false);
  const [toast, setToast] = useState(null);

  
  useEffect(() => {
    setUpdatedItems(VitalsItems);
  }, [VitalsItems]);

  const handleChange = (index, key, value) => {
    const newItems = updatedItems.map((item, i) => {
      if (i === index) {
        return { ...item, [key]: value };
      }
      return item;
    });
    setUpdatedItems(newItems);
  };

  const handleTimeChange = (index, time) => {
    handleChange(index, "time", time ? time.toISOString() : "");
  };

  const addNewVitalsRow = () => {
    const newVital = {
      BP: "",
      HR: 0,
      RR: 0,
      SPO2: 0,
      T: 0,
      W: 0,
      time: moment.tz("America/Phoenix").toDate(),
    };

    setUpdatedItems([...updatedItems, newVital]);
  };

  const handleFinish = () => {
    const defaultVital = {
      HR: 0,
      BP: "",
      T: 0,
      W: 0,
      RR: 0,
      SPO2: 0,
      time: moment.tz("America/Phoenix").toDate(),
    };

    const validatedItems = updatedItems.map((item) => {
      return {
        HR: item.HR || defaultVital.HR,
        BP: item.BP || defaultVital.BP,
        T: item.T || defaultVital.T,
        W: item.W || defaultVital.W,
        RR: item.RR || defaultVital.RR,
        SPO2: item.SPO2 || defaultVital.SPO2,
        time: item.time || defaultVital.time,
      };
    });

    handleCellChange(patientId, "vitals", validatedItems, "vitals");
    onClose();
  };

  const handlePrevious = () => {
    handleOpenTreatmentNoteModal(recordForNotes);
    onClose();
  };

  const findPreviousTreatment = (treatments) => {
    let previousTreatment = null;
    let currentDate = new Date(selectedDate);
    let found = false;

    while (!found && currentDate) {
      currentDate = getPreviousDate(currentDate);
      previousTreatment = treatments.treatments.find((treatment) => {
        let treatmentDateString = new Date(treatment.date)
          .toISOString()
          .split("T")[0];

        return treatmentDateString === currentDate;
      });

      if (previousTreatment) {
        found = true;
      }
    }

    return previousTreatment;
  };
  const findCurrentTreatment = (treatments) => {
    let currentTreatment = null;
    let currentDate = new Date(selectedDate).toISOString().split("T")[0];
    let found = false;

    currentTreatment = treatments.treatments.find((treatment) => {
      let treatmentDateString = new Date(treatment.date)
        .toISOString()
        .split("T")[0];
      return treatmentDateString === currentDate;
    });

    if (currentTreatment) {
      found = true;
    }

    return currentTreatment;
  };

  const getPreviousDate = (dateString) => {
    const currentDate = new Date(dateString);
    const previousDate = new Date(currentDate);
    previousDate.setDate(previousDate.getDate() - 1);
    return previousDate.toISOString().split("T")[0];
  };
  const showToast = (variant, message) => {
    setToast({ variant, message });

    setTimeout(() => {
      setToast(null);
    }, 5000);
  };

  const handleCopyVitalsFromPreviousTreatment = async () => {
    setModalLoader(true);
    try {
      const previousTreatments = await axios.get(
        `/api/patient/${patientIdForPreviousTreatment}/last-treatments`
      );

      if (previousTreatments.data.totalCount <= 0) {
        showToast(
          "error",
          "There are no vitals from the previous treatment to copy from"
        );
        return;
      }

      const treatment = findPreviousTreatment(previousTreatments.data);
      const currentTreatment = findCurrentTreatment(previousTreatments.data);

      if (currentTreatment.review == true) {
        showToast(
          "error",
          "The status of this treatment is reviewed, so labs can't be copied"
        );
        setModalLoader(false);
        return;
      }

      const previousVitals = treatment.vitals;
      if (previousVitals.length <= 0) {
        setModalLoader(false);

        showToast(
          "error",
          "There are no vitals from the previous treatment to copy from"
        );
        return;
      }
      const validatedItems = previousVitals.map((item) => {
        return {
          HR: item.HR,
          BP: item.BP,
          T: item.T,
          W: item.W,
          RR: item.RR,
          SPO2: item.SPO2,
          time: item.time,
        };
      });

      handleCellChange(patientId, "vitals", validatedItems, "vitals");
      setModalLoader(false);

      showToast("success", "Successfully copied labs from previous treatment");
      setUpdatedItems(validatedItems);
    } catch (error) {
      console.error("Error fetching previous treatments:", error);
      setModalLoader(false);

      showToast("error", "Failed to fetch previous treatments");
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <div className="flex items-center">
        <DialogTitle>
          <span className="vitalstitleStyling">
            <IconButton onClick={handlePrevious}>
              <ChevronLeft />
            </IconButton>
            Update Vitals Items
            <IconButton disabled={true}>
              <ChevronRight />
            </IconButton>
          </span>
        </DialogTitle>

        <Tooltip
          title={
            <h1 className="text-sm">Copy Vitals From Previous Treatment</h1>
          }
          placement="right"
        >
          <IconButton onClick={handleCopyVitalsFromPreviousTreatment}>
            <ContentCopyRounded sx={{ fontSize: 30 }} />
          </IconButton>
        </Tooltip>
      </div>
      {modalLoader && (
        <div className="loader-backdrop">
          <BounceLoader color="#000" />
        </div>
      )}
      <div>
        {toast && (
          <CustomToast
            message={toast.message}
            variant={toast.variant}
            customStyles={{ bottom: "25%" }}
          />
        )}
        <div className="activeStylingD">{VitalsItems?.patientName}</div>
        <div className="flex w-full justify-end">
          <button className="newVitalBtn" onClick={addNewVitalsRow}>
            Add New Vitals
          </button>
        </div>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow className="rowStyling">
                <TableCell>BP</TableCell>
                <TableCell>HR</TableCell>
                <TableCell>RR</TableCell>
                <TableCell>SPO2</TableCell>
                <TableCell>T</TableCell>
                <TableCell>W</TableCell>
                <TableCell>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedItems.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <input
                      className="vitalsInput"
                      type="text"
                      value={item.BP}
                      onChange={(e) =>
                        handleChange(index, "BP", e.target.value)
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      className="vitalsInput"
                      type="number"
                      value={item.HR}
                      onChange={(e) =>
                        handleChange(index, "HR", e.target.value)
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      className="vitalsInput"
                      type="number"
                      value={item.RR}
                      onChange={(e) =>
                        handleChange(index, "RR", e.target.value)
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      className="vitalsInput"
                      type="number"
                      value={item.SPO2}
                      onChange={(e) =>
                        handleChange(index, "SPO2", e.target.value)
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      className="vitalsInput"
                      type="number"
                      value={item.T}
                      onChange={(e) => handleChange(index, "T", e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      className="vitalsInput"
                      type="number"
                      value={item.W}
                      onChange={(e) => handleChange(index, "W", e.target.value)}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TimePicker
                      className="datetimeStling"
                      use12Hours
                      format="h:mm a"
                      value={item.time ? moment(item.time) : null}
                      onChange={(time) => handleTimeChange(index, time)}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <button className="btn-color py-2 px-14" onClick={handleFinish}>
            Finish
          </button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default VitalsModal;
