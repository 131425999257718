import { SearchOutlined } from "@mui/icons-material";
import { Select } from "antd";
import React from "react";
const { Option } = Select;

const PatientSelect = ({
  onSelectPatient,
  patients,
  onSearch,
  highlightedIndex,
  selectedPatientName,
  setSelectedPatientId,
  setSelectedPatientName,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && highlightedIndex >= 0) {
      onSelectPatient(patients[highlightedIndex].id);
    }
  };
  return (
    <div>
      <Select
        showSearch
        style={{ width: 300 }}
        placeholder="Search for a patient"
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={onSearch}
        onChange={onSelectPatient}
        notFoundContent={null}
        onKeyDown={handleKeyDown}
        value={selectedPatientName} // Bind the state here
        allowClear
        onClear={() => {
          setSelectedPatientId("");
        }}
      >
        {patients.map((patient) => (
          <Option key={patient.id} value={patient.id}>
            {patient.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default PatientSelect;
