import React, { useState, useEffect } from "react";
import { Button, Modal, Select } from "antd";
import axios from "axios";

const { Option } = Select;

function DeleteAssignee({ showToast }) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [assignee, setassignee] = useState([]);
  const [selectedassignee, setSelectedassignee] = useState("");

  function showAssignee() {
    axios
      .get("/get-assignee-options", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setassignee(response.data[0].name);
      })
      .catch((error) => {
        console.error("There was an error fetching the lab assignees", error);
      });
  }

  const showModal = () => {
    showAssignee();
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    axios
      .delete(`/delete-assignee-option`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Add any other headers you need here
        },
        data: {
          assigneeOption: selectedassignee,
        },
      })
      .then((response) => {
        showToast("Lab assignee deleted successfully", "success");
        
        setConfirmLoading(false);
        setOpen(false);
        // Optionally refresh the list of lab assignees
      })
      .catch((error) => {
        console.error("There was an error deleting the lab assignee", error);
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    
    setOpen(false);
  };

  const handleassigneeChange = (value) => {
    
    setSelectedassignee(value);
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Delete Assignee Option
      </button>
      <Modal
        title="Delete Assignee"
        visible={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Select
          onChange={handleassigneeChange}
          value={selectedassignee}
          style={{ width: "100%" }}
        >
          {assignee.map((assignee) => (
            <Option key={assignee} value={assignee}>
              {assignee}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
}

export default DeleteAssignee;
