import "./Updations.css";
import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Form } from "antd";
import {
  TextField,
  Button as MuiButton,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

function EditVendorLabs({ showToast }) {
  const initialValues = {
    name: "",
    tube_name: "",
    ml_amount_limit: "",
  };
  const [Open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedLab, setSelectedLab] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [labs, setLabs] = useState([]);
  const [fields, setFields] = useState(initialValues);
  const [tubes, setTubes] = useState([]);

  const handleChangeTextField = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    updateLab();
  };

  const addTube = () => {
    if (!fields.tube_name || !fields.ml_amount_limit) {
      return;
    }
    setTubes([
      ...tubes,
      { name: fields.tube_name, ml_amount_limit: fields.ml_amount_limit },
    ]);
    setFields({ ...fields, tube_name: "", ml_amount_limit: "" });
  };

  const updateLab = async () => {
    
    
    axios
      .patch(
        `/vendorlab/${selectedLab?._id}`,
        { ...fields, tube_types: tubes },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      )
      .then((response) => {
        showToast("Saved", "success");
        setOpen(false);
      })
      .catch((error) => {
        showToast("Something went wrong");
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  const deleteTube = (index) => {
    const newTubes = tubes.filter((_, i) => i !== index);
    setTubes(newTubes);
  };

  const addLab = async () => {
    
    const payload = {
      name: fields.name,
      tube_types: tubes,
    };

    
    axios
      .post(`/vendorlab`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        showToast("Vendor Lab added", "success");
        setOpen(false);
      })
      .catch((error) => {
        showToast("Something went wrong");
      })
      .finally(() => {
        setConfirmLoading(false);
      });
  };

  useEffect(() => {
    setConfirmLoading(false);
  }, []);

  const handleCancel = () => {
    
    setOpen(false);
  };
  const fetchVendorLabs = async () => {
    axios
      .get("/vendorlab", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setLabs(response.data.vendors);
      })
      .catch((error) => {
        console.error("There was an error fetching the lab tests", error);
      });
  };
  useEffect(() => {
    fetchVendorLabs();
  }, [Open]);

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Edit Vendor Lab
      </button>
      <Modal
        title="Edit Vendor Lab"
        visible={Open} // Change open to visible
        onOk={handleOk}
        okText={"Edit Vendor Lab"}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form
          name="patientForm"
          initialValues={{ remember: true }}
          onFinish={handleOk} // You can change this to your form submit function
        >
          <div className="labsBody">
            <Autocomplete
              options={labs}
              value={selectedLab}
              getOptionLabel={(option) => option?.name || ""}
              getOptionKey={(option) => option._id}
              onChange={(event, newValue) => {
                setSelectedLab(newValue);
                setTubes(newValue?.tube_types || []);
                setFields({ ...initialValues, ...newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Lab" variant="outlined" />
              )}
            />
            <TextField
              label="Name"
              name="name"
              value={fields.item_number}
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <TextField
              label="Tube Name"
              name="tube_name"
              value={fields.tube_name}
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <TextField
              label="Limit (ml)"
              name="ml_amount_limit"
              type="number"
              value={fields.ml_amount_limit}
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <MuiButton variant="contained" onClick={addTube}>
              Add Tube
            </MuiButton>
            <List>
              {tubes.map((tube, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteTube(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={tube.name}
                    secondary={`${tube.ml_amount_limit} ml`}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default EditVendorLabs;
