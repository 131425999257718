import React, { useState, useEffect } from "react";
import { Button, Modal, Select } from "antd";
import axios from "axios";

const { Option } = Select;

function DeleteLabs({ showToast }) {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [labTests, setLabTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState("");

  function showLabs() {
    axios
      .get("/getLabTestNames", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setLabTests(response.data.labTestNames);
        
      })
      .catch((error) => {
        console.error("There was an error fetching the lab tests", error);
      });
  }

  const showModal = () => {
    showLabs();
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    axios
      .delete(
        `/deleteLabFromOrders?item_number=${selectedTest}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Add any other headers you need here
          },
        }
      )
      .then((response) => {
        showToast("Lab test deleted successfully", "success");
        
        setConfirmLoading(false);
        setOpen(false);
        // Optionally refresh the list of lab tests
      })
      .catch((error) => {
        console.error("There was an error deleting the lab test", error);
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    
    setOpen(false);
  };

  const handleTestChange = (value) => {
    
    setSelectedTest(value);
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Delete Lab
      </button>
      <Modal
        title="Delete Lab"
        visible={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Select
          onChange={handleTestChange}
          value={selectedTest}
          style={{ width: "100%" }}
        >
          {labTests.map((test) => (
            <Option
              key={test.test_name + test.item_number}
              value={test.item_number}
            >
              {`${test.test_name} (${test.item_number})`}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
}

export default DeleteLabs;
