import React, { useState } from "react";
import { Button, Modal, Input, Form, Row, Col } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import axios from "axios";
import "./Updations.css";

function AddSupplements({ showToast }) {
  const [form] = Form.useForm();
  const [isAddSupplementsOpen, setIsAddSupplementsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputFields, setInputFields] = useState([
    { dosage: "", variantId: "" },
  ]);

  const showAddSupplementsModal = () => {
    setIsAddSupplementsOpen(true);
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    const supplementData = {
      name: values.name,
      shopify_product_id: values.shopify_product_id,
      dosages: inputFields.map((field) => field.dosage),
      shopify_variant_ids: inputFields.map((field) => field.variantId),
    };

    try {
      const response = await axios.post(
        "/addSupplementToAllOrders",
        supplementData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Add any other headers you need here
          },
        }
      );
      showToast("Supplements added successfully", "success");
      form.resetFields();
      setInputFields([{ dosage: "", variantId: "" }]); // Reset input fields after submission
      setIsAddSupplementsOpen(false);
    } catch (error) {
      console.error("Error adding supplements:", error);
      showToast("Failed to add supplements", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setInputFields([{ dosage: "", variantId: "" }]); // Reset input fields on cancel
    setIsAddSupplementsOpen(false);
  };

  const addInputField = () => {
    setInputFields([...inputFields, { dosage: "", variantId: "" }]);
  };

  const handleInputChange = (e, index, type) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields[index][type] = e.target.value;
    setInputFields(updatedInputFields);
  };

  const removeInputField = (index) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields.splice(index, 1);
    setInputFields(updatedInputFields);
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showAddSupplementsModal}
      >
        Add Supplements
      </button>
      <Modal
        title="Add Supplements"
        visible={isAddSupplementsOpen}
        confirmLoading={isSubmitting}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        footer={[
          <Button
            key="back"
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            key="submit"
            type="primary"
            loading={isSubmitting}
            form="supplementForm"
            htmlType="submit"
          >
            {isSubmitting ? "Adding..." : "Add"}
          </Button>,
        ]}
      >
        <Form
          id="supplementForm"
          form={form}
          name="supplementForm"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item name="shopify_product_id">
            <Input placeholder="Shopify Product Id" />
          </Form.Item>
          <Button
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            className="mb-[5px]"
            type="primary"
            onClick={addInputField}
            block
          >
            Add Dosage
          </Button>
          {inputFields.map((field, index) => (
            <Row gutter={16} key={index}>
              <Col span={10}>
                <Form.Item>
                  <Input
                    value={field.dosage}
                    placeholder="Dosage"
                    onChange={(e) => handleInputChange(e, index, "dosage")}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item>
                  <Input
                    value={field.variantId}
                    placeholder="Variant ID"
                    onChange={(e) => handleInputChange(e, index, "variantId")}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button type="danger" onClick={() => removeInputField(index)}>
                  <DeleteTwoTone />
                </Button>
              </Col>
            </Row>
          ))}
        </Form>
      </Modal>
    </>
  );
}

export default AddSupplements;
