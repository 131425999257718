import React from "react";
import { BounceLoader } from "react-spinners";

const Loader = () => {
  return (
    <div
      style={{
        position: "fixed", // Use fixed to position relative to the viewport
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
        zIndex: 1000, // High zIndex to ensure it's above other elements
      }}
    >
      <BounceLoader color="#fff" />
    </div>
  );
};

export default Loader;
