import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import RoutesWrapper from "./RoutesWrapper";
import { Provider } from "./context/DataContext";

function App() {
  return (
    <>
      <div className="app-container">
        <div className="background-color"></div>
        <div className="content-container">
            <Router>
              <RoutesWrapper />
            </Router>
        </div>
      </div>
    </>
  );
}

export default App;
