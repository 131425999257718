import React, { useRef } from 'react';
import { Button } from 'antd';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import './PrintStyles.css';

class ComponentToPrint extends React.Component {
  calculateAge = (dob) => {
    return moment().diff(moment(dob), 'years');
  };

  renderHeader() {
    const { currentTreatment } = this.props;
    return (
      <div className="print-header" >
        <div>
          <strong>PATIENT:</strong><br />
          {this.props.currentTreatment.patientName}<br />
          DOB: {moment(this.props.currentTreatment.dob).format('YYYY-MM-DD')}<br />
          AGE: {this.calculateAge(this.props.currentTreatment.dob, new Date())}<br />
          SEX: {this.props.currentTreatment.sex || 'N/A'}
        </div>
        <div>
          <strong>FACILITY</strong><br />
          Sunridge Medical<br />
          T (480) 659-9135<br />
          F (480) 659-9197<br />
          14200 N Northsight Blvd 160<br />
          Scottsdale, AZ 85260
        </div>
        <div>
          <strong>ENCOUNTER Office Visit</strong><br />
          NOTE TYPE: {this.props.currentTreatment.type}<br />
          SEEN BY: {this.props.currentTreatment.seenBy || 'N/A'}<br />
          DATE: {moment(this.props.currentTreatment.date).format('YYYY-MM-DD')}<br />
          AGE AT DOS: {this.calculateAge(this.props.currentTreatment.dob, this.props.currentTreatment.date)}<br />
          Not signed
        </div>
      </div>
    );
  }

  renderFooter() {
    return (
      <div className="print-footer">
        <br /><br /><br />
        <p>Gio Franco</p>
        <p className="signature-style">Gio Franco</p>
        <p>Sunridge Medical</p>
        <p>{moment().format('DD-MM-YYYY')}</p>
      </div>
    );
  }

  renderPages() {
    const { editorContent } = this.props;
    const approximateCharactersPerPage = 1800;
    const maximumLinesPerPage = 25; // Adjust based on your average line count per page

    const parser = new DOMParser();
    const doc = parser.parseFromString('<div>' + editorContent + '</div>', 'text/html');
    const allNodes = Array.from(doc.body.firstChild.childNodes);

    const pages = [];
    let currentPageContent = '';
    let currentCharacterCount = 0;
    let currentLineCount = 0;

    allNodes.forEach(node => {
      let nodeHTML = node.outerHTML || node.textContent || '';
      const isEmptyLine = node.nodeType === Node.ELEMENT_NODE && node.tagName.toLowerCase() === 'p' && nodeHTML.trim() === '';
      const nodeCharacterCount = isEmptyLine ? 0 : nodeHTML.length; // Empty lines do not contribute to character count
      const nodeLineCount = isEmptyLine ? 1 : (nodeHTML.match(/<br>|<\/p>|<\/h[1-6]>/g) || []).length;

      if (currentCharacterCount + nodeCharacterCount > approximateCharactersPerPage || currentLineCount + nodeLineCount > maximumLinesPerPage) {
        // Page limit reached, push the current page content and start a new page
        pages.push(currentPageContent);
        currentPageContent = nodeHTML;
        currentCharacterCount = nodeCharacterCount;
        currentLineCount = nodeLineCount;
      } else {
        // Accumulate content
        currentPageContent += nodeHTML;
        currentCharacterCount += nodeCharacterCount;
        currentLineCount += nodeLineCount;
      }
    });

    if (currentPageContent) {
      pages.push(currentPageContent);
    }

    return pages.map((pageContent, index) => (
      <div key={index} className="print-page">
        {this.renderHeader()}
        <div className="note-title">
          <h2><strong>{this.props.currentTreatment.custom}</strong></h2>
          <p>Created on: {moment(this.props.currentTreatment.date).format('YYYY-MM-DD')}</p>
          <br />
        </div>
        <div className="page-content" dangerouslySetInnerHTML={{ __html: pageContent }} />
        {index === pages.length - 1 && this.renderFooter()}
      </div>
    ));
  }

 
  render() {
    return <div>{this.renderPages()}</div>;
  }
}

const PrintTreatmentNotes = ({ currentTreatment, editorContent }) => {
  const componentRef = useRef();

  return (
    <div>
      <ReactToPrint
        trigger={() => <Button>Print Notes</Button>}
        content={() => componentRef.current}
        pageStyle="@page { size: letter; margin: 20mm 10mm; }"
      />
      <div style={{ display: 'none' }}>
        <ComponentToPrint
          ref={componentRef}
          currentTreatment={currentTreatment}
          editorContent={editorContent}
        />
      </div>
    </div>
  );
};


export default PrintTreatmentNotes;
