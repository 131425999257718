import "./Updations.css";
import React, { useState, useEffect } from "react";
import { Button, Modal, Select, Space } from "antd";
import axios from "axios";

function DeleteProcedure({ showToast }) {
  const [Open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [procedureNames, setProcedureNames] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);

  function procedureName() {
    axios
      .get("/listAllProcedureNames", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setProcedureNames(response.data);
      })
      .catch((error) =>
        console.error("Error fetching procedure names:", error)
      );
  }

  const showModal = () => {
    procedureName();
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    axios
      .delete("/removeProcedureFromAllOrders", {
        data: { procedureName: selectedProcedure },

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Add any other headers you need here
        },
      })
      .then((response) => {
        showToast("Procedure deleted successfully", "success");
        
        setOpen(false);
        setConfirmLoading(false);
        setSelectedProcedure(null);
      })
      .catch((error) => console.error("Error deleting procedure:", error));
  };

  const handleCancel = () => {
    
    setOpen(false);
  };

  const handleProcedureChange = (value) => {
    setSelectedProcedure(value);
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Delete Procedure
      </button>
      <Modal
        title="Delete Procedure"
        visible={Open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select a procedure"
          optionFilterProp="children"
          onChange={handleProcedureChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {procedureNames.map((name) => (
            <Select.Option key={name} value={name}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  );
}

export default DeleteProcedure;
