import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Form, Row, Col, Select, Tooltip } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

function UpdateDispense({ showToast }) {
  const [updateDispenseOpen, setUpdateDispenseOpen] = useState(false);
  const [confirmUpdateDispenseLoading, setConfirmUpdateDispenseLoading] =
    useState(false);
  const [dispensedItems, setDispensedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [inputFields, setInputFields] = useState([]);
  const [shopifyVariantIds, setShopifyVariantIds] = useState([]);
  const [shopifyProductId, setShopifyProductId] = useState("");
  const [selectedDispensed, setSelectedDispensed] = useState(null);
  const fetchDispensedItems = async () => {
    try {
      const response = await axios.get("/allDispensed", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      });
      setDispensedItems(response.data.allDispensed);
      
    } catch (error) {
      console.error("Error fetching dispensed items:", error);
    }
  };

  const showUpdateDispenseModal = () => {
    fetchDispensedItems();
    setUpdateDispenseOpen(true);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      selectedDispensed.dosages,
      result.source.index,
      result.destination.index
    );

    const variantIds = reorder(
      shopifyVariantIds,
      result.source.index,
      result.destination.index
    );
    
    setSelectedDispensed((prevState) => ({
      ...prevState,
      dosages: items,
      shopify_variant_ids: variantIds,
    }));

    setShopifyVariantIds(variantIds);
  };

  const handleUpdateDispenseOk = async () => {
    setConfirmUpdateDispenseLoading(true);

    const updatedDosages = inputFields.filter(
      (field) => field.trim().length > 0
    );

    const updateData = {
      itemName: selectedItem,
      newDosages: selectedDispensed.dosages,
      shopify_product_id: shopifyProductId,
      shopify_variant_ids: shopifyVariantIds,
    };

    await updateDispensedItem(updateData);
    setUpdateDispenseOpen(false);
    setConfirmUpdateDispenseLoading(false);
  };

  const handleUpdateDispenseCancel = () => {
    setUpdateDispenseOpen(false);
  };

  const handleItemChange = (itemName) => {
    
    setSelectedItem(itemName);
    const selectedItemData = dispensedItems.find(
      (item) => item.name === itemName
    );
    setSelectedDispensed(selectedItemData);
    setInputFields(selectedItemData ? selectedItemData.dosages : []);
    setShopifyProductId(
      selectedItemData ? selectedItemData?.shopify_product_id : ""
    );
    setShopifyVariantIds(
      selectedItemData ? selectedItemData?.shopify_variant_ids : []
    );
  };

  const addInputField = () => {
    setInputFields([...inputFields, ""]);
  };

  const handleInputChange = (e, index, field) => {
    if (field === "dosages") {
      const updatedInputFields = [...inputFields];
      updatedInputFields[index] = e.target.value;
      setInputFields(updatedInputFields);
    } else if (field === "shopify_variant_ids") {
      const updatedVariantIds = [...shopifyVariantIds];
      updatedVariantIds[index] = e.target.value;
      setShopifyVariantIds(updatedVariantIds);
    }
  };

  const removeInputField = (index) => {
    const updatedInputFields = [...inputFields];
    const updatedVariantIds = [...shopifyVariantIds];
    updatedInputFields.splice(index, 1);
    updatedVariantIds.splice(index, 1);
    setInputFields(updatedInputFields);
    setShopifyVariantIds(updatedVariantIds);
  };

  const updateDispensedItem = async (updateData) => {
    
    try {
      const response = await axios.put(
        "/update-dispensed-dosages",
        updateData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Add any other headers you need here
          },
        }
      );
      showToast("Dispensed updated successfully", "success");
      
    } catch (error) {
      console.error("Error updating dispensed item:", error);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: "rgb(235,235,235)",
    }),
  });

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showUpdateDispenseModal}
      >
        Update Dispensed Item
      </button>
      <Modal
        title="Update Dispensed Item"
        visible={updateDispenseOpen}
        onOk={handleUpdateDispenseOk}
        confirmLoading={confirmUpdateDispenseLoading}
        onCancel={handleUpdateDispenseCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form name="updateDispenseForm">
          <Form.Item
            name="itemSelection"
            rules={[{ required: true, message: "Please select an item" }]}
          >
            <Select
              placeholder="Select an Item"
              onChange={handleItemChange}
              style={{ width: "100%" }}
            >
              {dispensedItems.map((item) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="dosage-container">
            {selectedDispensed && selectedDispensed.dosages && (
              <>
                <button
                  style={{
                    color: "white",
                    backgroundColor: "#1976d2",
                    borderRadius: "5px",
                    fontWeight: "bold",
                  }}
                  className="py-1 px-10 w-full md:w-auto"
                  type="primary"
                  onClick={addInputField}
                >
                  Add Dosage
                </button>
                <Tooltip title="Add Shopify Product Id">
                  <Input
                    value={shopifyProductId}
                    onChange={(e) => setShopifyProductId(e.target.value)}
                    style={{
                      marginLeft:
                        selectedDispensed.dosages.length > 0 ? "15px" : "0px",
                      width:
                        selectedDispensed.dosages.length > 0 ? "60%" : "100%",
                    }}
                  />
                </Tooltip>
                <div
                  className="dosage-list"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {selectedDispensed.dosages.map((item, index) => (
                            <Draggable
                              key={index.toString()} // Ensure the key is a string
                              draggableId={index.toString()} // Ensure the draggableId is a string
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Row
                                  gutter={16}
                                  className="dosage-field"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <Col span={2}>
                                    <DragIndicatorIcon />
                                  </Col>
                                 
                                  <Col span={8}>
                                    <Input
                                      value={item}
                                      onChange={(e) =>
                                        handleInputChange(e, index, "dosages")
                                      }
                                      style={{ marginRight: "10px" }}
                                    />
                                  </Col>
                                  <Col span={8}>
                                    <Tooltip title="Add Shopify Variant Id">
                                      <Input
                                        value={shopifyVariantIds[index]}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            index,
                                            "shopify_variant_ids"
                                          )
                                        }
                                        style={{ marginRight: "10px" }}
                                      />
                                    </Tooltip>
                                  </Col>
                                  <Col span={6}>
                                    <button
                                      className="btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto"
                                      type="danger"
                                      onClick={() => removeInputField(index)}
                                    >
                                      <DeleteTwoTone
                                        style={{ fontSize: "20px" }}
                                      />
                                    </button>
                                  </Col>
                                </Row>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </>
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default UpdateDispense;
