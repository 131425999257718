import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const PickUpModal = ({
  open,
  onClose,
  patientId,
  selectedDate,
  patientName,
}) => {
  const [selectedDispensed, setSelectedDispensed] = useState([]);
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [availableDispensed, setAvailableDispensed] = useState([]);
  const [availableSupplements, setAvailableSupplements] = useState([]);

  const fetchDispensed = async () => {
    try {
      const response = await axios.get(
        "/dispensed-names",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setAvailableDispensed(response.data);
    } catch (error) {
      console.error("Error fetching dispensed items:", error);
    }
  };

  const fetchSupplements = async () => {
    try {
      const response = await axios.get(
        "/supplements-names",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setAvailableSupplements(response.data);
    } catch (error) {
      console.error("Error fetching supplement names:", error);
    }
  };
  useEffect(() => {
    fetchDispensed();
    fetchSupplements();
  }, []);

  const handleDispensedChange = (index, field, name) => (event) => {
    const newSelectedDispensed = [...selectedDispensed];
    newSelectedDispensed[index] = {
      ...newSelectedDispensed[index],
      [field]: event.target.value,
      name: name,
    };

    
    // If a dosage is selected, set the quantity to 1
    if (field === "dosage" && event.target.value) {
      newSelectedDispensed[index].quantity = "1";
    }

    setSelectedDispensed(newSelectedDispensed);
  };

  const handleSupplementChange = (index, field, name) => (event) => {
    const newSelectedSupplements = [...selectedSupplements];
    newSelectedSupplements[index] = {
      ...newSelectedSupplements[index],
      [field]: field === "checked" ? event.target.checked : event.target.value,
      name: name,
    };

    // If the checkbox is checked, set the quantity to 1
    if (field === "checked" && event.target.checked) {
      newSelectedSupplements[index].quantity = "1";
    }

    setSelectedSupplements(newSelectedSupplements);
  };

  const handleSubmit = async () => {
    
    // Filter out items in selectedDispensed that have both dosage and quantity
    const filteredDispensed = selectedDispensed.filter(
      (item) => item.dosage && item.quantity
    );

    // Filter out items in selectedSupplements that are checked and have quantity
    const filteredSupplements = selectedSupplements.filter(
      (item) => item.checked && item.quantity
    );

    // Prepare the data to be sent
    const data = {
      dispensed: filteredDispensed,
      supplements: filteredSupplements,
      patientId: patientId, // Assuming patientId is available in this scope
    };

    // Send the data to the endpoint
    
    const response = await fetch(
      `/schedule-pickup/${selectedDate}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      

      setSelectedDispensed([]);
      setSelectedSupplements([]);
      onClose();
      toast.success("Pick up scheduled successfully!");
    } else {
      console.error("Error sending data:", response.statusText);
      toast.error("Error scheduling pick up!");
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle
        style={{
          backgroundColor: "#3f51b5",
          color: "white",
          textAlign: "center",
          fontFamily: "Arial",
        }}
      >
        Pick Up for {patientName}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid
            item
            xs={6}
            style={{
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "20px",
              marginBottom: "20px",
              backgroundColor: "#f5f5f5",
              boxShadow: "0px 3px 6px #ccc",
              overflow: "auto",
            }}
          >
            <h2
              style={{
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
                marginBottom: "20px",
                fontFamily: "Arial",
                color: "#3f51b5",
              }}
            >
              Dispensed
            </h2>
            {availableDispensed &&
              availableDispensed.length > 0 &&
              availableDispensed.map((dispensed, index) => (
                <Grid container spacing={2} alignItems="center" key={index}>
                  <Grid item xs={4}>
                    <h3 style={{ fontFamily: "Arial", color: "#3f51b5" }}>
                      {dispensed.name}
                    </h3>
                  </Grid>
                  <Grid item xs={5}>
                    <Select
                      value={selectedDispensed[index]?.dosage || ""}
                      onChange={handleDispensedChange(
                        index,
                        "dosage",
                        dispensed.name
                      )}
                    >
                      {dispensed.dosages.map((dosage, i) => (
                        <MenuItem key={i} value={dosage}>
                          {dosage}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      value={selectedDispensed[index]?.quantity || ""}
                      onChange={handleDispensedChange(
                        index,
                        "quantity",
                        dispensed.name
                      )}
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "20px",
              marginBottom: "20px",
              backgroundColor: "#f5f5f5",
              boxShadow: "0px 3px 6px #ccc",
              overflow: "auto",
            }}
          >
            <h2
              style={{
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
                marginBottom: "20px",
                fontFamily: "Arial",
                color: "#3f51b5",
              }}
            >
              Supplements
            </h2>
            {availableSupplements &&
              availableSupplements.length > 0 &&
              availableSupplements.map((supplement, index) => (
                <Grid container spacing={2} alignItems="center" key={index}>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedSupplements[index]?.checked || false}
                          onChange={handleSupplementChange(
                            index,
                            "checked",
                            supplement
                          )}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <h3 style={{ fontFamily: "Arial", color: "#3f51b5" }}>
                      {supplement}
                    </h3>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      value={selectedSupplements[index]?.quantity || ""}
                      onChange={handleSupplementChange(
                        index,
                        "quantity",
                        selectedSupplements[index]?.name
                      )}
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          style={{ backgroundColor: "#3f51b5", color: "white" }}
        >
          Submit
        </Button>
        <Button
          onClick={onClose}
          style={{ backgroundColor: "#f44336", color: "white" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PickUpModal;
