import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { DatePicker, Spin } from "antd";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  IconButton,
  DialogActions,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
  Box,
  Button,
} from "@mui/material";
import "./vitalsModel.css";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import Loader from "../Loader";

const EventsModal = ({
  open,
  treatments,
  events: initialEvents,
  ignoredEvents,
  patients,
  currentCalendar,
  onClose,
  handleCellChange,
  status,
  fetchTreatments,
  formattedDate,
  setStatus,
  fetchEventsNew,
  completedCalendar,
  arrivedCalendar,
  pickupCalendar,
  scheduledCalendar,
  allEvents,
}) => {
  const [searchText, setSearchText] = useState("");
  const events =
    searchText && allEvents?.length > 0
      ? allEvents.filter((event) =>
          event.summary.toLowerCase().includes(searchText.toLowerCase())
        )
      : initialEvents;

  const [showAssociated, setShowAssociated] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showIgnored, setShowIgnored] = useState(false);
  const [showUnAssociated, setShowUnAssociated] = useState(true);
  const [selectedPatients, setSelectedPatients] = useState({});
  const [openConfirmationNT, setOpenConfirmationNT] = useState(false);
  const [openConfirmationET, setOpenConfirmationET] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedPatient, setSelectedPatient] = useState({});
  const [selectedTreatment, setSelectedTreatment] = useState({});
  const [loading, setLoading] = useState(false);
  const [expandedNotes, setExpandedNotes] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    dayjs(moment()?.format("YYYY/MM/DD"), "YYYY/MM/DD")
  );

  useEffect(() => {
    let calendar;
    switch (status) {
      case "completed":
        calendar = completedCalendar;
        break;
      case "arrived":
        calendar = arrivedCalendar;
        break;
      case "pickup":
        calendar = pickupCalendar;
        break;
      case "scheduled":
        calendar = scheduledCalendar;
        break;
      default:
        calendar = null; // Handle default case
    }

    if (calendar) {
      fetchEventsNew(calendar, status, selectedDate?.format("YYYY-MM-DD")).then(
        () => {
          setLoading(false);
        }
      );
    }
  }, [selectedDate]);

  const handlePreviousDate = () => {
    setLoading(true);
    const previousDate = selectedDate.subtract(1, "day");
    setSelectedDate(previousDate);
    // fetchCalendars(previousDate.format("YYYY-MM-DD")); // Assuming fetchCalendars is defined elsewhere
  };

  const handleNextDate = () => {
    setLoading(true);
    const nextDate = selectedDate.add(1, "day");
    setSelectedDate(nextDate);
  };

  const handleToggleExpand = (eventId) => {
    setExpandedNotes({
      ...expandedNotes,
      [eventId]: !expandedNotes[eventId],
    });
  };
  const handleConfirmationNT = () => {
    setOpenConfirmationNT(false);

    if (selectedEvent && selectedPatient) {
      handlePatientChange(selectedEvent, selectedPatient);
    }
  };

  const handleCancelNT = () => {
    setOpenConfirmationNT(false);
  };

  const handleChangeNT = (eventId, data) => {
    setSelectedEvent(eventId);
    setSelectedPatient(data);
    setOpenConfirmationNT(true);
  };

  const handleConfirmationET = () => {
    setOpenConfirmationET(false);

    if (selectedEvent && selectedTreatment) {
      updateTreatmentWithEventDataTime(selectedEvent, selectedTreatment);
    }
  };

  const handleCancelET = () => {
    setOpenConfirmationET(false);
  };

  const handleChangeET = (eventId, data) => {
    setSelectedEvent(eventId);

    setSelectedTreatment(data);

    setOpenConfirmationET(true);
  };

  const filteredTreatments = treatments.filter(
    (treatment) => treatment.status === status
  );

  const filteredTreatmentsUnassigned = treatments.filter(
    (treatment) => treatment.event === "unassigned" || !treatment.event
  );

  const filteredEventsAssociated = events.filter((event) =>
    filteredTreatments.some((treatment) => treatment.event === event.id)
  );

  const filteredEventsUnAssociated =
    treatments.length === 0
      ? events.filter((event) =>
          ignoredEvents.every(
            (ignoredEvent) => ignoredEvent.eventId !== event.id
          )
        )
      : events.filter(
          (event) =>
            treatments.every((treatment) => treatment.event !== event.id) &&
            ignoredEvents.every(
              (ignoredEvent) => ignoredEvent.eventId !== event.id
            )
        );

  const filteredEventsIgnored = events.filter((event) =>
    ignoredEvents.some((eve) => eve.eventId === event.id && eve.ignored)
  );

  const createTreatmentWithEventDataTime = async (
    eventId,
    selectedPatientId
  ) => {
    setLoading(true);
    const event = events.find((e) => e.id === eventId);
    if (!selectedPatientId || !event) {
      console.error("Missing data for creating treatment");
      toast.error("Missing data for creating treatment");
      return;
    }

    const eventDate = moment(event?.start?.dateTime)?.format(
      "YYYY-MM-DDT00:00:00.000[Z]"
    );
    const eventTime = moment(event?.start?.dateTime)?.format("hh:mm A");

    try {
      const response = await axios.post(
        "/date/time/order",
        {
          PatientId: selectedPatientId,
          date: eventDate,
          time: eventTime,
          event: event.id,
          type: "Treatment",
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      const responseWatch = await axios.post(
        `/create-watch`,
        {
          eventId: event?.id,
          treatmentId: response.data?._id,
          calendarId: currentCalendar,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toast.success("Treatment created successfully");
      // onClose();
      fetchTreatments(formattedDate);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
      console.error("Error creating treatment:", error);
    }
  };

  const updateTreatmentWithEventDataTime = async (eventId, treatment) => {
    setLoading(true);
    const event = events.find((e) => e.id === eventId);
    if (!event) {
      console.error("Missing data for creating treatment");
      toast.error("Missing data for creating treatment");
      return;
    }

    const eventDate = moment(event?.start?.dateTime)?.format(
      "YYYY-MM-DDT00:00:00.000[Z]"
    );
    const eventTime = moment(event?.start?.dateTime)?.format("hh:mm A");

    try {
      handleCellChange(treatment?._id, "event", event?.id, "patientInfo");
      handleCellChange(treatment?._id, "date", eventDate, "patientInfo");
      handleCellChange(treatment?._id, "time", eventTime, "patientInfo");
      const responseWatch = await axios.post(
        `/create-watch`,
        {
          eventId: event?.id,
          treatmentId: treatment?._id,
          calendarId: currentCalendar,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toast.success("Treatment assigned with event successfully");
      // onClose();
      fetchTreatments(formattedDate);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
      console.error("Error creating treatment:", error);
    }
  };

  async function ChangeEventIgnoreStatus(eventId, ignore) {
    setLoading(true);
    try {
      const response = await axios.post(
        `/ignore-calendar-event/${eventId}`,
        {
          ignored: ignore,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      fetchTreatments(formattedDate);
      if (!ignore) {
        toast.success(`Event ${ignore ? "ignore" : "unignored"} successfully`);
        // onClose();
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
      console.error(
        `Error ${ignore ? "ignoring" : "unignoring"} Event :`,
        error
      );
    }
  }

  const handlePatientChange = (eventId, newValue) => {
    const selectedPatientId = newValue ? newValue["Customer ID"] : null;

    setSelectedPatients((prevSelectedPatients) => ({
      ...prevSelectedPatients,
      [eventId]: newValue,
    }));

    if (selectedPatientId) {
      createTreatmentWithEventDataTime(eventId, selectedPatientId);
    }
  };

  useEffect(() => {
    setSelectedPatients({});
  }, [events]);

  const unassignEvent = async (patientId, eventId) => {
    const event = events.find((e) => e.id === eventId);
    if (!event) {
      console.error("Event not found");
      return;
    }

    const eventDate = moment(event?.start?.dateTime)?.format(
      "YYYY-MM-DDT00:00:00.000[Z]"
    );
    const eventTime = moment(event?.start?.dateTime)?.format("hh:mm A");
    try {
      const response = await axios.patch(
        `/updateEvent/${patientId}`,
        {
          newEvent: "unassigned",
          eventDate,
          eventTime,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      // onClose();
      fetchTreatments(formattedDate);

      toast.success("Event unassigned successfully");
    } catch (error) {
      toast.error(error.message);
      console.error("Error unassigning event:", error);
    }
  };
  const capitalizedText =
    status?.charAt?.(0)?.toUpperCase() + status?.slice?.(1);

  const handleNext = () => {
    setLoading(true);
    switch (status) {
      case "scheduled":
        setStatus("arrived");
        fetchEventsNew(arrivedCalendar, "arrived").then(() => {
          setLoading(false);
        });
        break;
      case "arrived":
        setStatus("pickup");
        fetchEventsNew(pickupCalendar, "pickup").then(() => {
          setLoading(false);
        });
        break;
      case "pickup":
        setStatus("complete");
        fetchEventsNew(completedCalendar, "complete").then(() => {
          setLoading(false);
        });
        break;
      default:
        setLoading(false);
        break;
    }

    fetchTreatments(formattedDate);
  };

  const handlePrevious = () => {
    setLoading(true);
    switch (status) {
      case "complete":
        setStatus("pickup");
        fetchEventsNew(pickupCalendar, "pickup").then(() => {
          setLoading(false);
        });
        break;
      case "arrived":
        setStatus("scheduled");
        fetchEventsNew(scheduledCalendar, "scheduled").then(() => {
          setLoading(false);
        });
        break;
      case "pickup":
        setStatus("arrived");
        fetchEventsNew(arrivedCalendar, "arrived").then(() => {
          setLoading(false);
        });
        break;
      default:
        setLoading(false);
        break;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setShowAll(false);
        setShowAssociated(false);
        setShowIgnored(false);
      }}
      maxWidth="lg"
      fullWidth
    >
      {loading && (
        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50">
          <Spin />
        </div>
      )}
      <Box p={4}>
        {" "}
        <DialogTitle>
          <div className="flex justify-between items-center">
            <span className="vitalstitleStyling">
              {" "}
              <IconButton
                onClick={handlePrevious}
                disabled={status === "scheduled"}
              >
                <ChevronLeft />
              </IconButton>
              {capitalizedText}
              <IconButton onClick={handleNext} disabled={status === "complete"}>
                <ChevronRight />
              </IconButton>
            </span>
            <Input
              prefix={<SearchOutlined />}
              className="ml-0.5 font-type"
              placeholder="Search"
              style={{
                width: "25%",
                height: "38.65px",
                borderRadius: "5px",
                backgroundColor: "#F7F7F7",
                border: "none",
              }}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div>
              <IconButton onClick={handlePreviousDate}>
                <ChevronLeft />
              </IconButton>
              <DatePicker
                selected={selectedDate}
                value={selectedDate}
                format="YYYY/MM/DD"
                onChange={(date) => {
                  setLoading(true);
                  setSelectedDate(date);
                }}
              />
              <IconButton onClick={handleNextDate}>
                <ChevronRight />
              </IconButton>
            </div>
            <div>
              <button
                className={`ml-[5px  ${
                  !showAll && showUnAssociated && "border border-1"
                } p-2 rounded-md`}
                onClick={() => {
                  setShowAll(false);
                  setShowAssociated(false);
                  setShowIgnored(false);
                  setShowUnAssociated(true);
                }}
              >
                Unlinked
              </button>
              <button
                className={`ml-[5px  ${
                  !showAll && showAssociated && "border border-1"
                } p-2 rounded-md`}
                onClick={() => {
                  setShowAll(false);
                  setShowAssociated(true);
                  setShowUnAssociated(false);
                  setShowIgnored(false);
                }}
              >
                Linked
              </button>

              <button
                className={`ml-[5px  ${
                  !showAll && showIgnored && "border border-1"
                } p-2 rounded-md`}
                onClick={() => {
                  setShowAll(false);
                  setShowIgnored(true);
                  setShowAssociated(false);
                  setShowUnAssociated(false);
                }}
              >
                Ignored
              </button>
              <button
                className={`ml-[5px  ${
                  showAll && "border border-1"
                } p-2 rounded-md`}
                onClick={() => {
                  setShowAll(true);
                  setShowIgnored(true);
                  setShowAssociated(true);
                  setShowUnAssociated(true);
                }}
              >
                All
              </button>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Table>
            {events.length > 0 &&
              showUnAssociated &&
              filteredEventsUnAssociated.length > 0 && (
                <>
                  <TableHead>
                    Not Assigned
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Start Time</TableCell>
                      {searchText !== "" && <TableCell>Calendar</TableCell>}
                      {filteredEventsUnAssociated.some(
                        (event) => event?.description
                      ) && <TableCell>Note</TableCell>}
                      <TableCell>Link</TableCell>
                      <TableCell>New Treatment</TableCell>
                      <TableCell>Existing Treatment</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {events.length > 0 &&
                      filteredEventsUnAssociated.map((event, index) => (
                        <TableRow key={index}>
                          <TableCell>{event?.summary}</TableCell>
                          <TableCell>
                            {moment(event?.start?.dateTime)?.format("HH:mm A")}
                          </TableCell>
                          {searchText !== "" && (
                            <TableCell>{event?.calendarType}</TableCell>
                          )}
                          {filteredEventsUnAssociated.some(
                            (event) => event?.description
                          ) && (
                            <TableCell>
                              {event?.description && (
                                <>
                                  {expandedNotes[event.id]
                                    ? event.description
                                    : event.description
                                        .split(" ")
                                        .slice(0, 3)
                                        .join(" ")}
                                  {event.description.split(" ").length > 3 && (
                                    <>
                                      {!expandedNotes[event.id] && (
                                        <span>...</span>
                                      )}
                                      <Button
                                        onClick={() =>
                                          handleToggleExpand(event.id)
                                        }
                                        size="small"
                                      >
                                        {expandedNotes[event.id]
                                          ? "Collapse"
                                          : "Expand"}
                                      </Button>
                                    </>
                                  )}
                                </>
                              )}
                            </TableCell>
                          )}
                          <TableCell>
                            <a
                              href={event?.htmlLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Event
                            </a>
                          </TableCell>
                          <TableCell>
                            <Autocomplete
                              id={`patient-autocomplete-${index}`}
                              options={patients.sort((a, b) => {
                                // Check if either option exactly matches event?.summary
                                if (a?.["Full Name"] === event?.summary)
                                  return -1;
                                if (b?.["Full Name"] === event?.summary)
                                  return 1;

                                // Otherwise, prioritize options that start with event?.summary
                                if (a?.["Full Name"].startsWith(event?.summary))
                                  return -1;
                                if (b?.["Full Name"].startsWith(event?.summary))
                                  return 1;

                                // If none of the above conditions are met, maintain the original order
                                return 0;
                              })}
                              style={{ width: 250 }}
                              getOptionLabel={(option) => option?.["Full Name"]}
                              value={selectedPatients[index] || null}
                              onChange={(_, newValue) => {
                                handleChangeNT(event.id, newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Patient"
                                  variant="outlined"
                                />
                              )}
                              isOptionEqualToValue={(option, value) =>
                                option?.["Full Name"] === value?.["Full Name"]
                              }
                            />
                          </TableCell>

                          <TableCell>
                            <Autocomplete
                              id={`treatment-autocomplete-${index}`}
                              // options={filteredTreatmentsUnassigned}
                              options={treatments}
                              style={{ width: 250 }}
                              getOptionLabel={(option) => option["patientName"]}
                              // value={selectedPatients[index] || null}
                              onChange={(_, newValue) => {
                                handleChangeET(event.id, newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Treatment"
                                  variant="outlined"
                                />
                              )}
                              isOptionEqualToValue={(option, value) =>
                                option["patientName"] === value["patientName"]
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <div className="ml-[5px]">
                              <Button
                                color="secondary"
                                variant="contained"
                                onClick={() =>
                                  ChangeEventIgnoreStatus(event?.id, true)
                                }
                              >
                                Ignore
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </>
              )}

            {events.length > 0 &&
              showAssociated &&
              filteredEventsAssociated.length > 0 && (
                <>
                  {" "}
                  <TableHead>
                    Assigned
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Start Time</TableCell>
                      {filteredEventsUnAssociated.some(
                        (event) => event?.description
                      ) && <TableCell>Note</TableCell>}
                      <TableCell>Link</TableCell>
                      <TableCell>Selected</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {events.length > 0 &&
                      filteredEventsAssociated.map((event, index) => {
                        const associatedTreatment = filteredTreatments.find(
                          (treatment) => treatment.event === event.id
                        );
                        const associatedPatient = patients.find(
                          (patient) =>
                            patient["Customer ID"] ===
                            associatedTreatment?.PatientId
                        );

                        return (
                          <TableRow key={index}>
                            <TableCell>{event?.summary}</TableCell>
                            <TableCell>
                              {moment(event?.start?.dateTime)?.format(
                                "HH:mm A"
                              )}
                            </TableCell>
                            {filteredEventsUnAssociated.some(
                              (event) => event?.description
                            ) && (
                              <TableCell>
                                {" "}
                                {event?.description && (
                                  <>
                                    {expandedNotes[event.id]
                                      ? event.description
                                      : event.description
                                          .split(" ")
                                          .slice(0, 3)
                                          .join(" ")}
                                    {event.description.split(" ").length >
                                      3 && (
                                      <>
                                        {!expandedNotes[event.id] && (
                                          <span>...</span>
                                        )}
                                        <Button
                                          onClick={() =>
                                            handleToggleExpand(event.id)
                                          }
                                          size="small"
                                        >
                                          {expandedNotes[event.id]
                                            ? "Collapse"
                                            : "Expand"}
                                        </Button>
                                      </>
                                    )}
                                  </>
                                )}
                              </TableCell>
                            )}
                            <TableCell>
                              <a
                                href={event?.htmlLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Event
                              </a>
                            </TableCell>
                            <TableCell>
                              {associatedPatient?.["Full Name"]}
                            </TableCell>
                            <TableCell>
                              <div className="flex">
                                {" "}
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  onClick={() =>
                                    unassignEvent(
                                      associatedPatient?.["Customer ID"],
                                      event.id
                                    )
                                  }
                                >
                                  Unassign
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </>
              )}

            {events.length > 0 &&
              showIgnored &&
              filteredEventsIgnored.length > 0 && (
                <>
                  {" "}
                  <TableHead>
                    Ignored
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Start Time</TableCell>
                      {filteredEventsUnAssociated.some(
                        (event) => event?.description
                      ) && <TableCell>Note</TableCell>}
                      <TableCell>Link</TableCell>
                      <TableCell>Selected</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {events.length > 0 &&
                      filteredEventsIgnored.map((event, index) => {
                        const associatedTreatment = filteredTreatments.find(
                          (treatment) => treatment.event === event.id
                        );
                        const associatedPatient = patients.find(
                          (patient) =>
                            patient["Customer ID"] ===
                            associatedTreatment?.PatientId
                        );

                        return (
                          <TableRow key={index}>
                            <TableCell>{event?.summary}</TableCell>
                            <TableCell>
                              {moment(event?.start?.dateTime)?.format(
                                "HH:mm A"
                              )}
                            </TableCell>
                            {filteredEventsUnAssociated.some(
                              (event) => event?.description
                            ) && (
                              <TableCell>
                                {" "}
                                {event?.description && (
                                  <>
                                    {expandedNotes[event.id]
                                      ? event.description
                                      : event.description
                                          .split(" ")
                                          .slice(0, 3)
                                          .join(" ")}
                                    {event.description.split(" ").length >
                                      3 && (
                                      <>
                                        {!expandedNotes[event.id] && (
                                          <span>...</span>
                                        )}
                                        <Button
                                          onClick={() =>
                                            handleToggleExpand(event.id)
                                          }
                                          size="small"
                                        >
                                          {expandedNotes[event.id]
                                            ? "Collapse"
                                            : "Expand"}
                                        </Button>
                                      </>
                                    )}
                                  </>
                                )}
                              </TableCell>
                            )}
                            <TableCell>
                              <a
                                href={event?.htmlLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Event
                              </a>
                            </TableCell>
                            <TableCell>
                              {associatedPatient?.["Full Name"]}
                            </TableCell>
                            <TableCell>
                              <div className="flex">
                                <div className="ml-[5px]">
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() =>
                                      ChangeEventIgnoreStatus(event?.id, false)
                                    }
                                  >
                                    Unignore
                                  </Button>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </>
              )}
          </Table>
        </DialogContent>
      </Box>
      <Dialog open={openConfirmationNT} onClose={handleCancelNT}>
        <Box p={2}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure to assign event to new treatment?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelNT} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmationNT} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog open={openConfirmationET} onClose={handleCancelET}>
        <Box p={2}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure to assign event to existing treatment?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelET} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmationET} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <ToastContainer />
    </Dialog>
  );
};

export default EventsModal;
