import React from "react";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { Button } from "antd";
import { Button as MuiButton } from "@mui/material";
import moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const Header = ({
  styles,
  selectedDate,
  setSelectedDate,
  datePickerDate,
  setDatePickerDate,
}) => {
  const navigateDate = (days) => {
    const newDate = moment(selectedDate).add(days, "days");
    setSelectedDate(newDate.toDate());
  };

  const handleDateChange = (date, dateString) => {
    console.log(date);
    console.log(dateString);
    setDatePickerDate(date);
    setSelectedDate(date.toDate());
  };

  return (
    <header className="bg-white shadow p-4 flex flex-col md:flex-row justify-between items-center">
      <div className="flex items-center space-x-4 mb-4 md:mb-0">
        <h1 className="text-2xl font-bold mb-0">Today View</h1>
        <div className="flex items-center space-x-2">
          <MuiButton color={"inherit"} onClick={() => navigateDate(-1)}>
            <ChevronLeft className="h-4 w-4" />
          </MuiButton>
          <span>{moment(selectedDate).format("dddd, MMMM D YYYY")}</span>
          <MuiButton color={"inherit"} onClick={() => navigateDate(1)}>
            <ChevronRight className="h-4 w-4" />
          </MuiButton>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <Button
          className={styles.button}
          onClick={() => {
            const today = dayjs();
            setSelectedDate(today.toDate());
            setDatePickerDate(today);
          }}
        >
          Today
        </Button>
        <DatePicker
          value={dayjs(selectedDate)}
          onChange={handleDateChange}
          format={"DD-MM-YYYY"}
        />
      </div>
    </header>
  );
};

export default Header;
