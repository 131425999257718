import React, { useEffect, useState } from "react";
import axios from "axios";

export const DataContext = React.createContext();

export const Provider = ({ children }) => {
  const [isTyping, setIsTyping] = useState(false);
  const [data, setData] = useState({
    labs: [],
    dispensed: [],
    procedures: [],
  });

  const fetchLabs = () => {
    axios
      .get("/allLabs", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setData((prevData) => {
          return {
            ...prevData,
            labs: response.data.allLabs,
          };
        });
      })
      .catch((error) => {
        
      });
  };
  const fetchSupplements = () => {
    axios
      .get("/getAllSupplements", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setData((prevData) => {
          return {
            ...prevData,
            supplements: response.data,
          };
        });
      })
      .catch((error) => {
        
      });
  };
  const fetchDispensed = () => {
    axios
      .get("/allDispensed", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setData((prevData) => {
          return {
            ...prevData,
            dispensed: response.data.allDispensed,
          };
        });
      })
      .catch((error) => {
        console.error("There was an error fetching the dispenses", error);
      });
  };
  const fetchProcedures = () => {
    axios
      .get("/allProcedures", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setData((prevData) => {
          return {
            ...prevData,
            procedures: response.data.allProcedures,
          };
        });
      })
      .catch((error) => {
        console.error("There was an error fetching the procedures", error);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isTyping) {
        Promise.all([
          fetchLabs(),
          fetchDispensed(),
          fetchProcedures(),
          fetchSupplements(),
        ]);
      }
    }, 30000);
    return () => clearInterval(interval);
  }, [isTyping]);

  return (
    <DataContext.Provider value={{ data, setData, isTyping, setIsTyping }}>
      {children}
    </DataContext.Provider>
  );
};
