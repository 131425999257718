// Components/PatientSearch.js
import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";
import React from "react";

const { Option } = Select;

const PatientSearch = ({
  patients,
  onSearch,
  onSelectPatient,
  highlightedIndex,
  setHighlightedIndex,
  selectedPatientName,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && highlightedIndex >= 0) {
      onSelectPatient(patients[highlightedIndex].id);
    }
  };
  return (
    <Select
      showSearch
      style={{ width: 300 }}
      placeholder="Search for a patient"
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={onSearch}
      onChange={onSelectPatient}
      notFoundContent={null}
      suffixIcon={<SearchOutlined />}
      onKeyDown={handleKeyDown}
      value={selectedPatientName}
    >
      {patients.map((patient) => (
        <Option key={patient.id} value={patient.id}>
          {patient.name}
        </Option>
      ))}
    </Select>
  );
};

export default PatientSearch;
