import "./Updations.css";
import React, { useState, useEffect } from "react";
import { Button, Modal, Select, Form } from "antd";
import axios from "axios";

function DeleteDispense({ showToast }) {
  const [Open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [dispensedItems, setDispensedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  function showDispensed() {
    axios
      .get("/listAllDispensedItems", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setDispensedItems(response.data);
      })
      .catch((error) =>
        console.error("Error fetching dispensed items:", error)
      );
  }

  const showModal = () => {
    showDispensed();
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    axios
      .delete("/removeDispensedFromAllOrders", {
        data: { itemName: selectedItem },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Add any other headers you need here
        },
      })
      .then((response) => {
        showToast("Dispensed deleted successfully", "success");
        
        setOpen(false);
        setConfirmLoading(false);
        setSelectedItem(null);
      })
      .catch((error) => {
        console.error("Error deleting item:", error);
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    
    setOpen(false);
  };

  const handleSelectChange = (value) => {
    setSelectedItem(value);
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Delete Dispense
      </button>
      <Modal
        title="Delete Dispense"
        visible={Open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form name="deleteDispenseForm" initialValues={{ remember: true }}>
          <Form.Item
            name="dispenseSelect"
            rules={[
              { required: true, message: "Please select a dispensed item!" },
            ]}
          >
            <Select
              placeholder="Select a dispensed item"
              onChange={handleSelectChange}
              allowClear
            >
              {dispensedItems.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default DeleteDispense;
