import "./Updations.css";
import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Form } from "antd";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button as MuiButton,
  List,
  ListItem,
  IconButton,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";

function AddLabs({ showToast }) {
  const initialValues = {
    item_number: "",
    test_name: "",
    shopify_vendor_name: "",
    shopify_product_id: "",
    display_name: "",
    name: "",
    ml_amount: "",
  };
  const [Open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedTube, setSelectedTube] = useState(null);
  const [fields, setFields] = useState(initialValues);
  const [tubes, setTubes] = useState([]);
  const [form] = Form.useForm();
  const handleChangeTextField = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleVendorChange = (event) => {
    setSelectedVendor(event.target.value);
  };

  const handleTubeChange = (event) => {
    setSelectedTube(event.target.value);
  };
  const showModal = () => {
    setOpen(true);
  };
  const addTube = () => {
    
    if (!selectedTube || !fields.ml_amount) {
      return;
    }
    setTubes([
      ...tubes,
      { name: selectedTube.name, ml_amount: fields.ml_amount },
    ]);
    setFields({ ...fields, name: "", ml_amount: "" });
  };

  const deleteTube = (index) => {
    const newTubes = tubes.filter((_, i) => i !== index);
    setTubes(newTubes);
  };

  const resetFormAndCloseModal = () => {
    form.resetFields();
    setFields(initialValues);
    setSelectedVendor(null);
    setSelectedTube(null);
    setTubes([]);
    setOpen(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        // API call to add lab
        axios
          .post(
            "/addLabToOrders",
            {
              checked: false,
              ...fields,
              tube_type: tubes,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                // Add any other headers you need here
              },
            }
          )
          .then((response) => {
            
            showToast("Lab added successfully", "success");
            resetFormAndCloseModal();
            setOpen(false);
          })
          .catch((error) => {
            console.error("Error adding lab:", error);
          })
          .finally(() => {
            setConfirmLoading(false);
          });
      })
      .catch((info) => {
        
      });
  };

  const fetchVendors = () => {
    axios
      .get("/vendorlab", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Add any other headers you need here
        },
      })
      .then((response) => {
        setVendors(response.data.vendors);
      })
      .catch((error) => {
        console.error("There was an error fetching the lab tests", error);
      });
  };

  const handleCancel = () => {
    
    setOpen(false);
    resetFormAndCloseModal();
    setConfirmLoading(false);
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Add Lab
      </button>
      <Modal
        title="Add Lab"
        visible={Open} // Change open to visible
        onOk={handleOk}
        okText={"Add"}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form
          name="patientForm"
          initialValues={{ remember: true }}
          onFinish={handleOk} // You can change this to your form submit function
          form={form}
        >
          <div className="labsBody">
            <TextField
              label="Item Number"
              name="item_number"
              value={fields.item_number}
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <TextField
              label="Test Name"
              name="test_name"
              value={fields.test_name}
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <TextField
              label="Display Name"
              name="display_name"
              value={fields.display_name}
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <TextField
              label="Shopify Vendor Name"
              name="shopify_vendor_name"
              value={fields.shopify_vendor_name}
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <TextField
              label="Shopify Product ID"
              name="shopify_product_id"
              value={fields.shopify_product_id}
              onChange={handleChangeTextField}
              margin="dense"
              fullWidth
            />
            <FormControl>
              <InputLabel id="vendor-select-label">Vendor</InputLabel>
              <Select
                labelId="vendor-select-label"
                label="Vendor"
                value={selectedVendor}
                onChange={handleVendorChange}
                fullWidth
                margin="dense"
              >
                {vendors.map((vendor, index) => (
                  <MenuItem key={index} value={vendor}>
                    {vendor.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedVendor && (
              <React.Fragment>
                <FormControl>
                  <InputLabel id="vendor-select-label">Tube</InputLabel>
                  <Select
                    labelId="vendor-select-label"
                    label="Vendor"
                    value={selectedTube}
                    onChange={handleTubeChange}
                    fullWidth
                    margin="dense"
                  >
                    {selectedVendor?.tube_types?.map((tube, index) => (
                      <MenuItem key={index} value={tube}>
                        {tube.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Limit (ml)"
                  name="ml_amount"
                  type="number"
                  value={fields.ml_amount}
                  onChange={handleChangeTextField}
                  margin="dense"
                  fullWidth
                />
                <MuiButton variant="contained" onClick={addTube}>
                  Add Tube
                </MuiButton>
                <List>
                  {tubes.map((tube, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => deleteTube(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={tube.name}
                        secondary={`${tube.ml_amount} ml`}
                      />
                    </ListItem>
                  ))}
                </List>
              </React.Fragment>
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default AddLabs;
