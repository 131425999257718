import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Header from "./Components/Header";
import styles from "./NewTodayView.module.css";
import PatientSelect from "./PatientSelect";
import axios from "axios";
import { message } from "antd";

const NewTodayView = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [datePickerDate, setDatePickerDate] = useState(moment());
  const [patientLoader, setPatientLoader] = useState(false);
  const [activePatient, setActivePatient] = useState([]);
  const [patients, setPatients] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [selectedPatientName, setSelectedPatientName] = useState("");
  const [selectedPatientId, setSelectedPatientId] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const controllerRef = useRef(null);
  useEffect(() => {
    getActivePatientProfiles();
  }, []);
  const getActivePatientProfiles = async () => {
    try {
      setPatientLoader(true);
      const response = await axios.get(
        `/patient-profiles/active`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        setActivePatient(response.data);
        setPatientLoader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
      }
      if (error.response && error.response.status === 409) {
      }
      console.error("Signup Error:", error);
    }
  };

  const handlePatientSelect = async (patientId, obj) => {
    console.log("patientselected", patientId, obj.children);
    if (!patientId) {
      message.error("Please select a patient");
    } else {
      setSelectedPatientId(patientId);
    }
    // const selectedPatientNameFound = patients.find(
    //   (patient) => patient["Customer ID"] === patientId
    // )["Full Name"];

    setSelectedPatientName(obj.children);
  };

  const handleSearch = async (value) => {
    setHighlightedIndex(0);
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    if (value) {
      try {
        const response = await axios.get(
          `/api/patients/search?query=${value}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
            signal: controller.signal,
          }
        );
        setPatients(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Previous request canceled:", error.message);
        } else {
          console.error("Error fetching patients:", error);
        }
      }
    } else {
      setPatients([]);
    }
  };
  return (
    <div className={`ml-[-38px] ${styles.container}`}>
      <Header
        styles={styles}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        datePickerDate={datePickerDate}
        setDatePickerDate={setDatePickerDate}
      />
      <div className="p-10">
        <PatientSelect
          onSearch={handleSearch}
          onSelectPatient={handlePatientSelect}
          selectedPatientId={selectedPatientId}
          selectedPatientName={selectedPatientName}
          patients={patients}
          highlightedIndex={highlightedIndex}
          setHighlightedIndex={setHighlightedIndex}
        />
      </div>
    </div>
  );
};

export default NewTodayView;
