import React, { useState } from "react";
import { Select, Modal, Input, Form } from "antd";
import axios from "axios";

const { Option } = Select;

export default function AddCalendar({ showToast }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => setIsModalOpen(true);

  const handleOk = async () => {
    const values = await form.validateFields();
    try {
      await axios.post("/google-calendar/add", values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });
      showToast("Calendar added successfully", "success");
      setIsModalOpen(false);
      form.resetFields();
    } catch (error) {
      showToast("Error adding calendar", "error");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  return (
    <>
      <button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showModal}
      >
        Add Calendar
      </button>
      <Modal
        title="Add New Calendar"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Events Section"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select event section">
              <Option value="Scheduled">Scheduled</Option>
              <Option value="Arrived">Arrived</Option>
              <Option value="Pickup">Pickup</Option>
              <Option value="Complete">Completed</Option>
              <Option value="cancelled">Cancelled</Option>
              <Option value="noshow">No Show</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="cid"
            label="Calendar ID"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            name="account"
            label="Account Data"
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}
