import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { Avatar, message, Spin, Typography } from "antd";
import { Input, Button } from "antd";
import { LoadingOutlined, SendOutlined as Send } from "@ant-design/icons";
import axios from "axios";

export default function ConversationSection({
  patientIds = [],
  showUnreadMessages = true,
  setUnreadMessagesCount = () => {},
}) {
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [messagesData, setMessagesData] = useState([]);
  const [recipientNumber, setRecipientNumber] = useState("");
  const [isSending, setIsSending] = useState(false);
  const divRef = useRef(null);

  const updateMessageReadStatus = useCallback(async () => {
    if (!recipientNumber) return;

    try {
      await axios.get(
        `/patient/update-messages-read-status`,
        {
          params: { phoneNumber: recipientNumber },
        }
      );
    } catch (error) {
      console.error("Error fetching conversation:", error);
      message.error("Failed to fetch conversation");
    }
  }, [recipientNumber]);

  useEffect(() => {
    const defaultPhone = selectedPerson?.phoneNumbers.find(
      (phone) => phone.isDefault === true || phone.isDefault === false
    );
    const newRecipientNumber = defaultPhone ? defaultPhone.number : "";
    setRecipientNumber(newRecipientNumber);

    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }

    if (newRecipientNumber) {
      updateMessageReadStatus();
    }
  }, [selectedPerson]);

  const handlePersonSelect = (person) => {
    setSelectedPerson(person);
    const defaultPhone = person?.phoneNumbers.find((phone) => phone.isDefault);
    const newRecipientNumber = defaultPhone ? defaultPhone.number : "";
    setRecipientNumber(newRecipientNumber);
    if (newRecipientNumber) {
      updateMessageReadStatus();
    }
    setMessagesData((prevMessagesData) =>
      prevMessagesData.map((p) =>
        p.patientId === person.patientId ? { ...p, unReadCount: 0 } : p
      )
    );
  };

  useEffect(() => {
    console.log("patientids", patientIds);
    if (showUnreadMessages && patientIds.length > 0) {
      fetchPatientWithIdsMessages(patientIds);
    }

    if (patientIds.length === 0 && showUnreadMessages) {
      setMessagesData([]);
    }
  }, [patientIds]);

  useEffect(() => {
    if (showUnreadMessages === false) {
      fetchPatientMessages();
    }
  }, []);

  const fetchPatientMessages = async () => {
    try {
      const response = await axios.get(
        "/patients/messages",

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        const currentActivePatientId = selectedPerson?.patientId;

        const data = showUnreadMessages
          ? response.data.filter((patient) => {
              if (currentActivePatientId === patient.patientId) {
                return true;
              }
              return patient.messages.some((message) => !message.readStatus);
            })
          : response.data;

        const dataWithUnreadCount = data.map((patient) => {
          return {
            ...patient,
            unReadCount: patient.messages.filter(
              (message) => message.readStatus === false
            ).length,
          };
        });

        // setUnreadMessageCount(
        //   dataWithUnreadCount.reduce((accumulator, currentValue) => {
        //     return accumulator + currentValue.unReadCount;
        //   }, 0)
        // );
        // setUnreadMessageCount(100);

        const sortedMessagesData = [...dataWithUnreadCount].sort(
          (a, b) => b.unReadCount - a.unReadCount
        );
        setMessagesData(sortedMessagesData);
      }
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      message.error("Failed to fetch messages");
    }
  };

  const fetchPatientWithIdsMessages = async (patientIds) => {
    try {
      const response = await axios.post(
        "/patient/messages",
        {
          patientIds: [...patientIds],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        const currentActivePatientId = selectedPerson?.patientId;

        const data = showUnreadMessages
          ? response.data.filter((patient) => {
              if (currentActivePatientId === patient.patientId) {
                return true;
              }
              return patient.messages.some((message) => !message.readStatus);
            })
          : response.data;

        const dataWithUnreadCount = data.map((patient) => {
          return {
            ...patient,
            unReadCount: patient.messages.filter(
              (message) => message.readStatus === false
            ).length,
          };
        });
        const sortedMessagesData = [...dataWithUnreadCount].sort(
          (a, b) => b.unReadCount - a.unReadCount
        );
        setMessagesData(sortedMessagesData);
        setUnreadMessagesCount(
          dataWithUnreadCount.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.unReadCount;
          }, 0)
        );
      }
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      message.error("Failed to fetch messages");
    }
  };

  useEffect(() => {
    if (messagesData.length && selectedPerson) {
      const updatedPerson = messagesData.find(
        (person) => person.patientId === selectedPerson.patientId
      );
      if (
        updatedPerson &&
        JSON.stringify(updatedPerson) !== JSON.stringify(selectedPerson)
      ) {
        setSelectedPerson(updatedPerson);
      }
    }
    console.log("************** messages data", messagesData);
  }, [messagesData, selectedPerson]);

  const handleSendMessage = async () => {
    if (!messageText.trim()) {
      return message.warning("Please enter a message.");
    }
    if (!recipientNumber) {
      return message.warning("Please enter a phone number first.");
    }

    setIsSending(true);
    try {
      await axios.post("/send-sms", {
        to: recipientNumber,
        text: messageText,
      });
      setMessageText("");
      message.success("Message sent successfully");
      fetchPatientMessages();
    } catch (error) {
      console.error("Error sending SMS:", error);
      message.error("Failed to send message");
    } finally {
      setIsSending(false);
    }
  };

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <div className="flex h-[300px] w-[800px] overflow-hidden rounded-lg border bg-background shadow-xl">
      {messagesData.length > 0 ? (
        <>
          {/* People list */}
          <div className="w-1/3 border-r">
            <div className="p-4 font-semibold">Chats</div>
            <div className="h-[calc(300px-56px)] overflow-x-auto">
              {messagesData.map((person) => (
                <div
                  key={person.patientId}
                  className={`flex items-center gap-3 p-3 cursor-pointer hover:bg-gray-200 ${
                    selectedPerson?.patientId === person.patientId &&
                    "bg-gray-100"
                  }`}
                  onClick={() => handlePersonSelect(person)}
                >
                  <Avatar>{person.patientName?.[0]}</Avatar>

                  <div className="overflow-hidden flex items-center">
                    <div className="font-medium">{person.patientName}</div>
                    {/* <div className="text-sm text-muted-foreground truncate"></div> */}
                    {person.unReadCount > 0 && (
                      <div className="text-[14px] bg-red-500 py-1 px-2 rounded-[50%] text-white ml-2">
                        {person.unReadCount}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Conversation */}
          <div className="flex flex-col w-2/3 ">
            {selectedPerson ? (
              <>
                <div className="border-b p-4 font-semibold">
                  {selectedPerson.patientName}
                </div>
                <div className="flex-grow p-4 overflow-auto" ref={divRef}>
                  {selectedPerson.messages.map((message) => (
                    <div
                      key={message.id}
                      className={`mb-4 flex ${
                        message.direction === "outbound"
                          ? "justify-end"
                          : "justify-start"
                      }`}
                    >
                      <div
                        className={`rounded-lg p-2 max-w-[70%] ${
                          message.direction === "outbound"
                            ? "bg-[#d0ecf0] "
                            : "bg-gray-200"
                        }`}
                      >
                        <Typography>{message.message}</Typography>
                        <p className="text-xs mt-1 opacity-70">
                          {formatDateTime(message.dateTime)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSendMessage();
                  }}
                  className="p-4 border-t flex gap-2"
                >
                  <Input
                    placeholder="Type a message..."
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                    className="flex-grow"
                  />
                  <Button
                    type="submit"
                    className="bg-black p-2"
                    size="icon"
                    onClick={() => {
                      handleSendMessage();
                    }}
                  >
                    {isSending ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ color: "white" }} spin />
                        }
                      />
                    ) : (
                      <Send className="h-4 w-4 text-white" />
                    )}
                  </Button>
                </form>
              </>
            ) : (
              <div className="flex items-center justify-center h-full text-muted-foreground">
                Select a chat to start messaging
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className="flex items-center justify-center h-full text-muted-foreground"
          style={{ margin: "0 auto" }}
        >
          {showUnreadMessages
            ? "There are no unread chats to show"
            : "There are no chats to show"}
        </div>
      )}
    </div>
  );
}
