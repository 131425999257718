// LabTableRow.jsx
import React, { useEffect, useState } from "react";
import {
  TableCell,
  TableRow,
  Checkbox,
  TextField,
  IconButton,
  Box,
  Typography,
  Button,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import axios from "axios";
import LabResultsDialog from './LabResultsDialog';

const cellStyle = {
  whiteSpace: "normal",
  wordWrap: "break-word",
  maxWidth: "350px",
  padding: "8px",
};

const MAX_VISIBLE_CHARS = 150;

const ExpandableTextField = ({ value, onChange, onBlur, disabled, multiline = true }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded(!expanded);

  
   // Check if value is undefined and set it to an empty string if so
   const safeValue = value === undefined ? '' : value;

   const visibleText = expanded ? safeValue : safeValue.slice(0, MAX_VISIBLE_CHARS);
   const showExpandButton = safeValue.length > MAX_VISIBLE_CHARS;
  return (
    <Box>
      <TextField
        value={visibleText}
        onChange={onChange}
        onBlur={onBlur}
        multiline={multiline}
        fullWidth
        disabled={disabled}
      />
      {showExpandButton && (
        <Button
          onClick={toggleExpand}
          startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          size="small"
        >
          {expanded ? "Show Less" : "Show More"}
        </Button>
      )}
    </Box>
  );
};

export const LabTableRow = ({ row, onUpdate,onG6PDClearedupdate,handleChangeRangeValue }) => {
  const [comments, setComments] = useState(row.comments);
  const [reviewScheduled, setReviewScheduled] = useState(row.reviewScheduled);
  const [reviewScheduledNote, setReviewScheduledNote] = useState(row.reviewScheduledNote);
  const [reviewedWithPatient, setReviewedWithPatient] = useState(row.reviewedWithPatient);
  const [openDialog, setOpenDialog] = useState(false);
  const [labResults, setLabResults] = useState(
    row.treatment.userLabs.map((lab) => ({
      ...lab,
      result: lab.result || false,
    }))
  );
  const [range, setRange] = useState("");
  const [g6pdCleared, setG6PDCleared] = useState(row.patientProfile.G6PD);

  const isOrder = row.treatment.type === "Order";

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const updateServer = async (data) => {
    if (isOrder) return;
    try {
      const response = await axios.patch(
        `/labtracker/${row._id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        onUpdate(response.data.updatedLabtracker);
      }
    } catch (error) {
      console.error(
        "Error updating lab tracker:",
        error.response?.data?.message || error.message
      );
    }
  };

  const handleTextFieldBlur = (field, value) => {
    if (!isOrder) updateServer({ [field]: value });
  };

  const handleRangeUpdate = async () => {
    try {
      const response = await axios.put(
        `/update-g6pd-range/${row?.treatment?._id}`,
        {rangeValue:range},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        handleChangeRangeValue(row?.treatment?._id,range)
        if (range > 100 && range < 200) {
          handleG6PDClearedUpdate(true)
        }
        else{
          handleG6PDClearedUpdate(false)
        }
      }
    } catch (error) {
      console.error(
        "Error updating lab tracker:",
        error.response?.data?.message || error.message
      );
    }
  }

  const handleG6PDClearedUpdate = async (value) => {
    try {
      const response = await axios.put(
        `/update-patient-profiles/${row?.treatment?.PatientId}`,
        {G6PD:value},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        setG6PDCleared(value)
        onG6PDClearedupdate(row?.treatment?.PatientId,value)
      }
    } catch (error) {
      console.error(
        "Error updating lab tracker:",
        error.response?.data?.message || error.message
      );
    }
  }

  const handleG6PDClearedChange = (event) => {
    if (event.target.checked) {
        handleG6PDClearedUpdate(true)
      }
    else {
      handleG6PDClearedUpdate(false)  
    }
  }
  const handleCheckboxChange = (field) => (event) => {
    if (isOrder) return;
    const newValue = event.target.checked;
    updateServer({ [field]: newValue });
    if (field === "reviewScheduled") setReviewScheduled(newValue);
    if (field === "reviewedWithPatient") setReviewedWithPatient(newValue);
  };

  const handleLabResultChange = async (lab, updateAll = false) => {
    if (isOrder) return;
    try {
      const response = await axios.patch(
        `/labresults/${row.treatment._id}`,
        {
          checked: updateAll ? !lab.result : !lab.result,
          type: "item_number",
          value: lab.item_number,
          updateAll: updateAll
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        if (updateAll) {
          setLabResults((prevResults) =>
            prevResults.map((prevLab) => ({
              ...prevLab,
              result: !lab.result
            }))
          );
        } else {
          setLabResults((prevResults) =>
            prevResults.map((prevLab) =>
              prevLab.item_number === lab.item_number
                ? { ...prevLab, result: !prevLab.result }
                : prevLab
            )
          );
        }
      }
    } catch (error) {
      console.error(
        "Error updating lab result:",
        error.response?.data?.message || error.message
      );
    }
  };

  useEffect(() => {
    const g6pdLab = row.treatment.userLabs.find(lab => lab.test_name === 'G6PD')
    if (g6pdLab && g6pdLab.checked === true && g6pdLab.range) {
      setRange(g6pdLab.range+'')
    } 
    setG6PDCleared(row.patientProfile.G6PD)
    
  },[labResults,row])
  const handleCheckAll = () => {
    const allChecked = labResults.every(lab => lab.result);
    handleLabResultChange({ result: allChecked }, true);
  };

  const getLabsDrawn = () => {
    const labsDrawnText = labResults
      .filter((lab) => lab.checked)
      .map((lab, index, array) => {
        const labText = lab.test_name + (index < array.length - 1 ? ", " : "");
        return `<span style="color: ${lab.result ? "black" : "red"}">${labText}</span>`;
      })
      .join("");
  
    return (
      <p dangerouslySetInnerHTML={{ __html: labsDrawnText }} />
    );
  };

  return (
    <TableRow
      style={{
        alignItems: "center",
        backgroundColor: isOrder ? "#FFD580" : "inherit",
      }}

      sx={{ border: 0.5 }}

    >
      <TableCell sx={cellStyle}>{row.patientName}</TableCell>
      <TableCell sx={cellStyle}>
        {new Date(row.treatment.date).toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })}
      </TableCell>
      <TableCell sx={cellStyle}>
        <Box sx={{ maxHeight: "100px", overflowY: "auto", display: "flex", alignItems: "center" }}>
          {getLabsDrawn()}
          <IconButton onClick={handleOpenDialog} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
        </Box>
        <LabResultsDialog
          open={openDialog}
          onClose={handleCloseDialog}
          labResults={labResults}
          onLabResultChange={handleLabResultChange}
          isOrder={isOrder}
          onCheckAll={handleCheckAll}
        />
      </TableCell>
      <TableCell sx={cellStyle}>
      { 
        row?.treatment?.userLabs?.some(lab => lab.test_name === "G6PD" && lab.checked === true) && (
          <ExpandableTextField
            value={range}
            onChange={(e) =>  setRange(e.target.value)}
            onBlur={() => handleRangeUpdate()}
            disabled={isOrder}
          />
        )
      }

      </TableCell>
      <TableCell sx={cellStyle}>
      {row?.treatment?.userLabs?.some(lab => lab.test_name === "G6PD" && lab.checked === true) && (
         <Checkbox checked={g6pdCleared} onChange={handleG6PDClearedChange}/>
        )}
      </TableCell>
      <TableCell sx={cellStyle}></TableCell>
      <TableCell sx={cellStyle}>
        <ExpandableTextField
          value={comments}
          onChange={(e) => !isOrder && setComments(e.target.value)}
          onBlur={() => handleTextFieldBlur("comments", comments)}
          disabled={isOrder}
        />
      </TableCell>
      <TableCell sx={cellStyle} style={{ display: "flex", gap: "10px" }}>
        <Checkbox
          checked={reviewScheduled}
          onChange={handleCheckboxChange("reviewScheduled")}
          disabled={isOrder}
        />
        <ExpandableTextField
          value={reviewScheduledNote}
          onChange={(e) => !isOrder && setReviewScheduledNote(e.target.value)}
          onBlur={() =>
            handleTextFieldBlur("reviewScheduledNote", reviewScheduledNote)
          }
          placeholder="Review Note"
          disabled={isOrder}
        />
      </TableCell>
      <TableCell sx={cellStyle}>
        <Checkbox
          checked={reviewedWithPatient}
          onChange={handleCheckboxChange("reviewedWithPatient")}
          disabled={isOrder}
        />
      </TableCell>
    </TableRow>
  );
};

export default LabTableRow;