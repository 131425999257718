import React from 'react'
import { Button } from "antd";
import { useNavigate } from 'react-router-dom';
import Logo from '../../Assets/Logo.png'
const PageNotFound = () => {
  const navigate=useNavigate();
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-8">
      <img src={Logo} width="300" alt="sunridge logo"/>
      <div className='flex flex-col gap-8 items-center justify-center bg-white shadow-xl  w-1/3 p-10 rounded-2xl'>
        <p className='font-bold text-8xl '>404</p>
        <p className='font-bold text-4xl '>Page Not Found</p>
        <Button className='btn-color py-2 px-3 text-xs md:text-sm h-auto w-full  md:w-auto' size='large' onClick={()=>navigate('/')}>Back To Home </Button>
      </div>
    </div>
  )
}

export default PageNotFound