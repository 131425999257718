import React, { useState } from 'react';
import { Modal, Select, DatePicker, TimePicker, Button, Spin, Input } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { ToastContainer, toast as toastifyToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { Option } = Select;

const ScheduleTreatmentModal = ({
  isOpen,
  closeModal,
  patientProfiles,
  createWatch,
  getCalendarCidByType,
  getCalendarIdByType,
  treatments,
  setTreatments
}) => {
  const [selectedPatientId, setSelectedPatientId] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [note, setNote] = useState('');
  const [showScheduleLoading, setShowScheduleLoading] = useState(false);
  const [showPickupLoading, setShowPickupLoading] = useState(false);

  // Handle patient select
  const handlePatientSelect = (value) => {
    setSelectedPatientId(value);
  };

  // Create a new schedule

  const createSchedule = async () => {
    setShowScheduleLoading(true);
    if (!selectedPatientId || !selectedDate || !selectedTime) {
      toastifyToast.error("Please select all fields");
      setShowScheduleLoading(false);
      return;
    }


    const datePart = selectedDate.format("YYYY-MM-DD");
    const timePart = selectedTime.format("hh:mm A");

    const combinedDateTime = moment.tz(`${datePart} ${timePart}`, "YYYY-MM-DD hh:mm A", "America/Phoenix");

    try {
      const response = await axios.post(
        "/patient/order/new/scheduled",
        {
          PatientId: selectedPatientId,
          Scheduled_Date: combinedDateTime.toISOString(), // Send the combined date-time in ISO format
          timezone: "America/Phoenix",
          type: "Treatment",
          status: "scheduled",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Schedule created successfully");
        createEventWithTime(
          timePart,
          "scheduled",
          response.data._id,
          "America/Phoenix",
          note,
          patientProfiles.find(p => p["Customer ID"] === selectedPatientId)?.["Full Name"],
          datePart
        );

        handleCellChange(response.data._id, "time", timePart, "patientInfo");

        if (treatments && treatments.length > 0 && setTreatments) {
          const selectedPatientName=patientProfiles.find(p => p["Customer ID"] === selectedPatientId)?.["Full Name"]
            setTreatments([
          ...treatments,
          { ...response.data, patientName: selectedPatientName },
        ]);
        }
      } else {
        toastifyToast.error("Failed to create schedule");
      }
    } catch (error) {
      toastifyToast.error(
        error.response?.data?.message || "An error occurred while creating the schedule"
      );
    } finally {
      setShowScheduleLoading(false);
    }
  };

  // Handle pickup
  const handlePickUp = async () => {
    setShowPickupLoading(true);
    if (!selectedPatientId || !selectedDate) {
      toastifyToast.error("Please select all fields");
      setShowPickupLoading(false);
      return;
    }

    const datePart = selectedDate.format("YYYY-MM-DD");
    let timePart;
    if(selectedTime){
     timePart = selectedTime.format("hh:mm A");
    }
    const combinedDateTime = moment.tz(`${datePart} ${timePart}`, "YYYY-MM-DD hh:mm A", "America/Phoenix");

    try {
      const response = await fetch(
        `/schedule-pickup/${datePart}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
          body: JSON.stringify({
            dispensed: [],
            supplements: [],
            patientId: selectedPatientId,
          }),
        }
      );
      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Pick up scheduled successfully!");
        const jsonResponse =await response.json()
        if (treatments && treatments.length > 0 && setTreatments) {
          const selectedPatientName=patientProfiles.find(p => p["Customer ID"] === selectedPatientId)?.["Full Name"]
            setTreatments([
          ...treatments,
          { ...jsonResponse, patientName: selectedPatientName },
        ]);
        }
        if (selectedTime) {
          createEventWithTime(
          timePart,
          "pickup",
          jsonResponse._id,
          "America/Phoenix",
          note,
          patientProfiles.find(p => p["Customer ID"] === selectedPatientId)?.["Full Name"],
          datePart
          );
          handleCellChange(jsonResponse._id, "time", timePart, "patientInfo");
        }
        else {
          closeModal()
        }

        
      } else {
        toastifyToast.error("Error scheduling pick up!");
      }
    } catch (error) {
      toastifyToast.error("An error occurred while scheduling the pickup.");
    } finally {
      setShowPickupLoading(false);
    }
  };

  // Call event creation function
  const createEventWithTime = async (
    time,
    status,
    treatmentId,
    timezone,
    note,
    patientName,
    selectedDate
  ) => {
    try {
      const cid = getCalendarCidByType(status);

      const response = await axios.post(
        `/create-new-event`,
        { time, patientName, timezone, note, cid, selectedDate }
      );
      const idc = getCalendarIdByType(status);
      createWatch(response.data.id, treatmentId, idc, status);
      toastifyToast.success("New event created for treatment");
      handleCellChange(treatmentId, "event", response.data.id, "patientInfo");

      setSelectedPatientId(undefined);
      setSelectedDate(null);
      setSelectedTime(null);
      closeModal();
    } catch (error) {
      toastifyToast.error("Error creating calendar event.");
      console.error(error);
    }
  };


  const handleCellChange = (
    patientId,
    columnName,
    newValue,
    category,
    newData,
  ) => {
    let payload;


    // Handling for other categories remains the same
    const identifierKey =
      category === "userProcedures" ? "procedure_name" : "name";
    let [itemName, field] = splitByLastDash(columnName);

    if (category === "patientInfo") {
      field = columnName;
    }

    payload = {
      category: category,
      identifier: {
        key: identifierKey,
        value: itemName,
      },
      updates: {
        [field]: newValue,
      },
      newData,
    };
    // Send the update request using axios
    axios
      .put(`/patient/order/update/${patientId}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      })
      .then((response) => {
      })
      .catch((error) => {
        toastifyToast.error("Error updating the treatment");
        console.error("Error:", error);
      });
  };


  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error("No dash found, or the dash is at the start of the string");
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }

  const disabledHours = () => {
    const hours = [];
    for (let i = 0; i < 8; i++) {
      hours.push(i);
    }
    for (let i = 19; i < 24; i++) {
      hours.push(i);
    }
    return hours;
  };


  return (
    <>
      <Modal
        title="Schedule a Treatment"
        visible={isOpen}
        onCancel={closeModal}
        footer={null}
      >
        {/* Patient Select */}
        <div className="mb-4">
          <Select
            className="w-full"
            placeholder="Select a patient"
            onChange={handlePatientSelect}
            value={selectedPatientId}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {patientProfiles.map(patient => (
              <Option key={patient._id} value={patient["Customer ID"]}>
                {patient["Full Name"]}
              </Option>
            ))}
          </Select>
        </div>

        {/* Date Picker */}
        <div className="mb-4">
          <DatePicker
            className="w-full"
            placeholder="Select date"
            onChange={setSelectedDate}
          />
        </div>

        {/* Time Picker */}
        <div className="mb-4">
          <TimePicker
            use12Hours
            format="h:mm A"
            minuteStep={15}
            allowClear={true}
            onChange={setSelectedTime}
            showNow={false}
            needConfirm={false}
            disabledHours={disabledHours}
            className="w-full"
            placeholder="Select time"
          />
        </div>

        {/* Note Input */}
        <div className="mb-4">
          <Input
            className="w-full"
            placeholder="Add a note"
            value={note}
            onChange={e => setNote(e.target.value)}
          />
        </div>


        {/* Action Buttons */}
        <div className="flex justify-end space-x-4">
          <Button onClick={closeModal}>
            Cancel
          </Button>
          <Button
            style={{
              color: "white",
              backgroundColor: "#3B82F6",
            }}
            onClick={createSchedule}
            loading={showScheduleLoading}
          >
            Schedule
          </Button>
          <Button
            style={{
              color: "white",
              backgroundColor: "#3B82F6",
            }}
            onClick={handlePickUp}
            loading={showPickupLoading}
          >
            Pick Up
          </Button>
        </div>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ScheduleTreatmentModal;
