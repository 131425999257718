// LabResultsDialog.jsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Button,
  Typography,
  FormControlLabel,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2, 3),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2, 3),
  },
  '& .MuiListItem-root': {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme, completed }) => ({
  '& .MuiTypography-root': {
    color: 'black',
    fontWeight: 'bold',
    padding: theme.spacing(0, 1),
  },
}));

const LabResultsDialog = ({
  open,
  onClose,
  labResults,
  onLabResultChange,
  isOrder,
  onCheckAll,
}) => {
  const allChecked = labResults.every((lab) => lab.result);

  const handleLabResultChange = (lab) => {
    onLabResultChange(lab);
  
  };

  const handleCheckAll = () => {
    onCheckAll();
  
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between" padding="1rem">
          <Typography variant="h6">Labs Drawn</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {!isOrder && (
          <Box mb={3} mt={1} padding="1rem">
            <FormControlLabel
              control={<Checkbox onChange={handleCheckAll} checked={allChecked} color="primary" />}
              label={<Typography variant="subtitle1">Check All</Typography>}
            />
          </Box>
        )}
        <Divider />
        <List sx={{ mt: 2 }}>
          {labResults
            .filter((lab) => lab.checked)
            .map((lab) => (
              <ListItem key={lab.item_number} disablePadding>
                <StyledListItemText
                  primary={lab.test_name}
                  completed={lab.result}
                />
                {!isOrder && (
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={() => handleLabResultChange(lab)}
                      checked={lab.result}
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" style={{ margin: "1rem" }}>
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default LabResultsDialog;