import React, { useState, useEffect, useRef } from "react";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import io from "socket.io-client";
import Person2Icon from "@mui/icons-material/Person2";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SmsIcon from "@mui/icons-material/Sms";
const socket = io("http://localhost:5000");

const Chats = () => {
  const [showChatBar, setShowChatBar] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(false);
  const [users, setUsers] = useState([]);
  const chatBoxRef = useRef();
  const [activeTab, setActiveTab] = useState("chats");
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [latestMessages, setLatestMessages] = useState({});

  useEffect(() => {
    if (authenticated) {
      socket.emit("joinChat", activeChatId);

      socket.on("newMessage", (newMessage) => {
        
        fetchMessages(activeChatId);
      });
    }

    return () => {
      socket.off("newMessage");
    };
  }, [activeChatId, authenticated]);

  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatBoxRef.current && !chatBoxRef.current.contains(event.target)) {
        setShowChatBar(false);
      }
    };

    if (showChatBar) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showChatBar]);

  const fetchChats = async () => {
    try {
      const token = localStorage.getItem("sessionToken");
      const response = await axios.get(
        `/chats/${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setChats(response.data);
      

      // Fetch latest message for each chat
      const latestMessagesMap = {};
      for (const chat of response.data) {
        if (chat.latestMessage) {
          const latestMessageResponse = await axios.get(
            `/message/${chat.latestMessage}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          latestMessagesMap[chat?._id] = latestMessageResponse.data;
        }
      }
      
      setLatestMessages(latestMessagesMap);
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  useEffect(() => {
    if (authenticated && user?.id) {
      fetchChats();
    }
  }, [authenticated]);

  const fetchMessages = async (chatId) => {
    if (!chatId) return;

    try {
      const token = localStorage.getItem("sessionToken");
      const response = await axios.get(
        `/messages/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessages(response.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("sessionToken");
      const response = await axios.get("/users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  return (
    <div
      className="fixed bottom-1 right-4 z-50 border border-1 border-gray-300 rounded-[10px]"
      ref={chatBoxRef}
    >
      {!showChatBar && (
        <IconButton onClick={() => setShowChatBar(!showChatBar)}>
          <ChatBubbleIcon style={{ fontSize: "40px" }} />
        </IconButton>
      )}

      {showChatBar && (
        <div className="mt-2 p-4 w-[500px] h-[400px] bg-white shadow-lg rounded-lg">
          <div className="flex items-center p-2 mb-4 bg-gray-200 rounded-[30px] space-x-4">
            <div className="flex-1 flex justify-center items-center border-r border-gray-400 text-center">
              <IconButton style={{ padding: 0, margin: 0 }}>
                {" "}
                {/* Adjust padding and margin */}
                <Person2Icon
                  style={{
                    fontSize: "20px",
                  }}
                />
              </IconButton>
              <div className="capitalize ml-1">{user?.username}</div>
            </div>
            <div
              className={`flex-1 cursor-pointer text-center border-r border-gray-400 ${
                activeTab === "chats" ? "font-bold" : "font-normal"
              }`}
              style={{ marginLeft: "-5px" }}
              onClick={() => {
                fetchChats();
                setActiveTab("chats");
              }}
            >
              Chats
            </div>
            <div
              className={`flex-1 cursor-pointer text-center ${
                activeTab === "users" ? "font-bold" : "font-normal"
              }`}
              style={{ marginLeft: "-5px" }}
              onClick={() => {
                fetchUsers();
                setActiveTab("users");
              }}
            >
              Users
            </div>
          </div>

          {activeTab === "chats" && (
            <div
              onClick={() => {
                setActiveTab("messages");
              }}
              className="flex-1 h-[320px] overflow-auto px-2"
            >
              {chats
                .sort((a, b) => {
                  const latestMessageA = latestMessages[a?._id];
                  const latestMessageB = latestMessages[b?._id];

                  if (!latestMessageA && !latestMessageB) return 0;
                  if (!latestMessageA) return 1;
                  if (!latestMessageB) return -1;

                  return (
                    new Date(latestMessageB.createdAt) -
                    new Date(latestMessageA.createdAt)
                  );
                })
                .map((chat) => {
                  const uname = user.username;
                  const otherUsername =
                    chat.users.find((user) => user.username !== uname)
                      ?.username || "Unknown User";

                  return (
                    <div
                      key={chat?._id}
                      onClick={() => {
                        setActiveChatId(chat?._id);
                        fetchMessages(chat?._id);
                        setMessageType("app");
                      }}
                      className="entry justify-center items-center cursor-pointer bg-white mb-3 rounded p-1 border-t border-gray-300 shadow-md flex"
                    >
                      <div className="flex-1">
                        <div className="flex items-center">
                          <div
                            className={`${
                              chat.chatName === "Group Chat" ? "" : "ml-[-10px]"
                            }`}
                          >
                            <IconButton>
                              {chat.chatName === "Group Chat" ? (
                                <PeopleAltIcon
                                  style={{
                                    fontSize: "25px",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                />
                              ) : (
                                <Person2Icon
                                  style={{
                                    fontSize: "30px",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                />
                              )}{" "}
                            </IconButton>
                          </div>
                          <div>
                            <div className="w-48">
                              <span className="text-gray-800 capitalize font-semibold">
                                {chat.chatName || otherUsername}
                              </span>
                            </div>
                            <div className="truncate">
                              {latestMessages[chat?._id]
                                ? latestMessages[chat?._id].message.length > 35
                                  ? `${latestMessages[chat?._id].message.slice(
                                      0,
                                      35
                                    )}...`
                                  : latestMessages[chat?._id].message
                                : "No messages yet"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-2 text-right">
                        <div>
                          {latestMessages[chat?._id] &&
                            moment(
                              latestMessages[chat?._id].createdAt
                            ).fromNow()}
                        </div>
                        <IconButton
                          title="SMS Chat"
                          onClick={(e) => {
                            e.stopPropagation();
                            setMessageType("sms");
                            setActiveChatId(chat?._id);
                            fetchMessages(chat?._id);
                            setActiveTab("messages");
                          }}
                        >
                          <SmsIcon style={{ fontSize: "20px" }} />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {activeTab === "users" && (
            <div
              onClick={() => setActiveTab("messages")}
              className="flex-1 h-[320px] overflow-auto px-2"
            >
              {users && user && (
                <UsersComponent
                  setActiveTab={setActiveTab}
                  fetchMessages={fetchMessages}
                  users={users}
                  user={user}
                  setActiveChatId={setActiveChatId}
                  setMessageType={setMessageType}
                />
              )}
            </div>
          )}

          {activeTab === "messages" && (
            <>
              {user && (
                <MessagesComponent
                  fetchMessages={fetchMessages}
                  messages={messages}
                  user={user}
                  activeChatId={activeChatId}
                  messageType={messageType}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const UsersComponent = ({
  setActiveTab,
  fetchMessages,
  users,
  user,
  setActiveChatId,
  setMessageType,
}) => {
  const handleUserClick = async (selectedUserId, type) => {
    try {
      const token = localStorage.getItem("sessionToken");
      const myUserId = user.id;

      const participants = [selectedUserId, myUserId];

      const response = await axios.post(
        "/chat",
        { users: participants, isGroupChat: false },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      

      if (response.data && response.data?._id) {
        setActiveChatId(response.data?._id);
        fetchMessages(response.data?._id);
        if (type && type === "sms") {
          setMessageType("sms");
        } else {
          setMessageType("app");
        }

        setActiveTab("messages");
      }
    } catch (error) {
      console.error("Error handling user click:", error);
    }
  };

  return (
    <>
      {users.map((user) => (
        <div
          key={user.id}
          onClick={() => handleUserClick(user.id)}
          className="entry items-center cursor-pointer bg-white mb-3 border-t border-gray-300 rounded p-2 flex shadow-md"
        >
          <div className="flex-1 px-2">
            <div className="flex items-center">
              <div className="ml-[-10px]">
                <IconButton>
                  <Person2Icon
                    style={{
                      fontSize: "30px",
                      margin: 0,
                      padding: 0,
                    }}
                  />
                </IconButton>
              </div>
              <div>
                <div className="truncate w-32">
                  <span className="text-gray-800 capitalize font-semibold">
                    {user.username}
                  </span>
                </div>
                <div>
                  <small className="text-gray-500">{user.email}</small>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-2 text-right">
            <IconButton
              title="SMS Chat"
              onClick={(e) => {
                e.stopPropagation();
                handleUserClick(user.id, "sms");
              }}
            >
              <SmsIcon style={{ fontSize: "20px" }} />
            </IconButton>
          </div>
        </div>
      ))}
    </>
  );
};

const MessagesComponent = ({
  fetchMessages,
  messages,
  user,
  activeChatId,
  messageType,
}) => {
  
  const messagesEndRef = useRef(null);
  const [messageInput, setMessageInput] = useState("");
  const [senderData, setSenderData] = useState({});
  const filteredMessages = messages.filter(
    (message) => message.sender._id !== user.id
  );
  

  const senderId = filteredMessages?.[0]?.sender._id;
  const sendMessage = async () => {
    
    if (!messageInput.trim()) return;
    try {
      const token = localStorage.getItem("sessionToken");
      let payload;

      if (messageType === "sms" && senderData.phoneNumber) {
        payload = {
          message: messageInput,
          sender: user.id,
          phoneNumber: senderData.phoneNumber,
          messageType: messageType,
          chatId: activeChatId,
        };
      }

      if (messageType === "app" && senderData.phoneNumber) {
        payload = {
          message: messageInput,
          sender: user.id,
          chatId: activeChatId,
        };
      }
      const response = await axios.post(
        "/message",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      
      setMessageInput("");
      fetchMessages(activeChatId);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const filteredMsgs = messages.filter(
    (message) => message.messageType === messageType
  );

  return (
    <>
      <div className="chat-container flex flex-col h-full">
        <div className="messages flex-1 overflow-auto px-2">
          {senderId && filteredMessages.length < 2 && (
            <SenderData
              senderId={senderId}
              senderData={sendMessage}
              setSenderData={setSenderData}
            />
          )}
          {filteredMsgs &&
            filteredMsgs.length > 0 &&
            filteredMsgs.map((message, index) => (
              <div
                key={index}
                className={`message mb-4 flex ${
                  message.sender?._id === user.id ? "justify-end" : ""
                }`}
              >
                <div className="px-2">
                  <div
                    className={`inline-block rounded-full p-2 px-6 ${
                      message.sender?._id === user.id
                        ? "bg-blue-600 text-white"
                        : "bg-gray-300 text-gray-700"
                    }`}
                  >
                    <span>{message.message}</span>
                  </div>
                </div>
              </div>
            ))}
          <div ref={messagesEndRef} /> {/* Invisible element at the end */}
        </div>
        <div className="input-container p-2 mb-9">
          <div className="write bg-white shadow flex rounded-lg">
            <input
              type="text"
              name="message"
              className="flex-1 outline-none py-2 px-4 bg-transparent"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              onKeyDown={(e) =>
                e.key === "Enter" && !e.shiftKey && sendMessage()
              }
              placeholder="Type a message..."
            />
            <IconButton onClick={sendMessage} color="primary">
              <SendIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

function SenderData({ senderId, senderData, setSenderData }) {
  

  useEffect(() => {
    getSenderData();
  }, [senderId]);

  async function getSenderData() {
    try {
      const token = localStorage.getItem("sessionToken");
      const response = await axios.get(
        `/get-user-by-id/${senderId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSenderData(response.data);
      
    } catch (err) {
      console.error("Error fetching user details:", err);
    }
  }
  return (
    <>
      {senderData.id && (
        <div className="entry cursor-pointer bg-white mb-2 rounded p-2 flex shadow-md">
          <div className="flex-1 px-2">
            <div className="flex items-center">
              <div className="ml-[-10px]">
                <IconButton>
                  <Person2Icon
                    style={{
                      fontSize: "30px",
                      margin: 0,
                      padding: 0,
                    }}
                  />
                </IconButton>
              </div>
              <div>
                <div className="truncate w-32">
                  <span className="text-gray-800 capitalize font-semibold">
                    {senderData.username}
                  </span>
                </div>
                <div>
                  <small className="text-gray-500">{senderData.email}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Chats;
