import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Form, Select, message } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "./Updations.css"; // Ensure your CSS file is properly included

const { Option } = Select;

function UpdateSupplements({ showToast }) {
  const [form] = Form.useForm();
  const [isUpdateSupplementsOpen, setIsUpdateSupplementsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [supplements, setSupplements] = useState([]);
  const [selectedSupplement, setSelectedSupplement] = useState(null);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    fetchSupplements();
  }, []);

  const fetchSupplements = async () => {
    try {
      const response = await axios.get(
        "/getAllSupplements"
      );
      setSupplements(response.data);
    } catch (error) {
      message.error("Failed to fetch supplements");
      console.error("Error fetching supplements:", error);
    }
  };

  const showUpdateSupplementsModal = () => {
    setIsUpdateSupplementsOpen(true);
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    // Check for empty variantId values
    const hasEmptyVariantIds = details.some((detail) => !detail.variantId);

    if (hasEmptyVariantIds) {
      message.error("Please fill in all Shopify Variant IDs.");
      setIsSubmitting(false);
      return;
    }

    try {
      await axios.post(
        "/updateSupplementInAllOrders",
        {
          supplementName: selectedSupplement.supplement_name,
          updatedSupplementData: {
            ...values,
            dosages: details.map((detail) => detail.dosage),
            shopify_variant_ids: details.map((detail) => detail.variantId),
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      showToast("Supplement updated successfully", "success");
      form.resetFields();
      setSelectedSupplement(null);
      setIsUpdateSupplementsOpen(false);
    } catch (error) {
      message.error("Failed to update supplement");
      console.error("Error updating supplement:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setSelectedSupplement(null);
    setIsUpdateSupplementsOpen(false);
  };

  const handleChange = (value) => {
    const selected = supplements.find((supplement) => supplement._id === value);
    setSelectedSupplement(selected);
    setDetails(
      selected
        ? selected.dosages.map((dosage, index) => ({
            dosage,
            variantId: selected.shopify_variant_ids[index] || "",
          }))
        : []
    );

    form.setFieldsValue({
      supplement_name: selected ? selected.supplement_name : "",
      shopify_product_id: selected ? selected.shopify_product_id : "",
    });
  };

  const handleAddDetail = () => {
    setDetails([...details, { dosage: "", variantId: "" }]);
  };

  const handleRemoveDetail = (index) => {
    const updatedDetails = [...details];
    updatedDetails.splice(index, 1);
    setDetails(updatedDetails);
  };

  const handleDetailChange = (index, key, value) => {
    const updatedDetails = [...details];
    updatedDetails[index][key] = value;
    setDetails(updatedDetails);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = reorder(
      details,
      result.source.index,
      result.destination.index
    );
    setDetails(items);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <>
      <Button
        className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
        onClick={showUpdateSupplementsModal}
      >
        Update Supplements
      </Button>
      <Modal
        title="Update Supplements"
        visible={isUpdateSupplementsOpen}
        confirmLoading={isSubmitting}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        footer={[
          <Button
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            key="submit"
            type="primary"
            loading={isSubmitting}
            form="updateSupplementForm"
            htmlType="submit"
            disabled={!selectedSupplement}
          >
            {isSubmitting ? "Updating..." : "Update"}
          </Button>,
        ]}
      >
        <Form
          id="updateSupplementForm"
          form={form}
          name="updateSupplementForm"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="supplement"
            label="Select Supplement"
            rules={[{ required: true, message: "Please select a supplement" }]}
          >
            <Select placeholder="Select a supplement" onChange={handleChange}>
              {supplements.map((supplement) => (
                <Option key={supplement._id} value={supplement._id}>
                  {supplement.supplement_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="supplement_name"
            label="Supplement Name"
            rules={[
              { required: true, message: "Please enter a supplement name" },
            ]}
          >
            <Input placeholder="Enter supplement name" />
          </Form.Item>
          <Form.Item name="shopify_product_id" label="Shopify Product ID">
            <Input placeholder="Enter Shopify product ID" />
          </Form.Item>
          <Button
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            type="primary"
            onClick={handleAddDetail}
          >
            Add Dosage
          </Button>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="dosage-list"
                >
                  {details.map((detail, index) => (
                    <Draggable
                      key={index}
                      draggableId={`draggable-${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="dosage-field"
                          style={{
                            ...provided.draggableProps.style,
                            backgroundColor: snapshot.isDragging
                              ? "lightgreen"
                              : "white",
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "5px",
                          }}
                        >
                          <DragIndicatorIcon />
                          <Input
                            placeholder="Enter dosage"
                            value={detail.dosage}
                            onChange={(e) =>
                              handleDetailChange(
                                index,
                                "dosage",
                                e.target.value
                              )
                            }
                            style={{ marginRight: "10px", width: "200px" }}
                          />
                          <Input
                            placeholder="Enter Shopify Variant ID"
                            value={detail.variantId}
                            onChange={(e) =>
                              handleDetailChange(
                                index,
                                "variantId",
                                e.target.value
                              )
                            }
                            style={{ marginRight: "10px", width: "200px" }}
                            required
                          />
                          <DeleteTwoTone
                            onClick={() => handleRemoveDetail(index)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Form>
      </Modal>
    </>
  );
}

export default UpdateSupplements;
